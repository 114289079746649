import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarRatings from 'react-star-ratings'; 
import Button from '@mui/material/Button';
import Tooltip  from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
var Router = require('react-router');
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DetailsCard(props) {
  var Link = Router.Link;
  const [expanded, setExpanded] = React.useState(false);
  const [DetailsNewURL, setDetailsNewURL] = useState("/detailsnew?zpid=" + props.zpid );
  const navigate = useNavigate();
  const handleClick = () => navigate('/PropDetails',{ state: { props: props } });
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  function buildSimilarPercentageString(num) {
        
    if (num<0){
      return num + '% below similar property average. '
    }else if (num>0) 
    {
      return num + '% above similar property average. '
    } 
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function getPriceAction(priceaction){
    //<p  style={{color:"red", align:"center",fontSize: "15px", display: 'inline' }}>JUST LISTED</p> <p style={{color:"red", align:"center",fontSize: "15px", display: (props.priceAction===null||props.priceAction===undefined|| props.priceAction==='')? 'none'  : 'inline'}}></p><p     style={{color:"red", align:"center",fontSize: "15px", display: props.priceAction==="REDUCED" ? 'none' : 'inline'}}>REDUCED</p>
    var returnPriceAction="     ";
    if(props.isNew){
        returnPriceAction = "JUST LISTED";
    }else if(props.priceAction!=null) {
        returnPriceAction=props.priceAction;
    }else {
        returnPriceAction=" ";
    }
    return returnPriceAction;



  }
  function checkPositive(num) {
      
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  return (
    <Card sx={{ maxWidth: 700 }}>

    
      <StarRatings  rating={props.ourRating} starRatedColor="#f5cb42" numberOfStars={10} name='rating' starDimension='18px'  starSpacing="2px" /><Typography style={{fontSize: "13px"}}> {     props.ourRating} out of 10</Typography>
      <Tooltip  title={<p style={{ fontSize: "18px", color:"White" }}>{props.investmentDecisionReason} </p>}>
                      <p style={{fontSize: "18px"}}>Why is it ranked this way?<IconButton><HelpOutlineIcon />    </IconButton></p>
                      </Tooltip> 
                      <br></br>
      {/* <CardMedia
        component="img"
        height="194"
        image={props.image}
        alt="Image Not Available"
      /> */}
    <Carousel showArrows={true} dynamicHeight={false} showThumbs={false}   autoPlay infiniteLoop='true'>
      {
        props.imageList.map( image => {
          return <div>
            <img src={ image.url } />
          
          </div>
        })
      }
    </Carousel>
               <CardContent>
      {/* <div style={{fontSize: "13px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="large" >{props.address}</div> */}
      <div style={{fontSize: "13px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="Medium" >LISTED PRICE: {props.price } </div>      
      <br></br>
      <div style={{fontSize: "15px",color:checkPositive(props.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(props.priceIncreasePercentage)}</div>
         <Typography variant="body2" color="text.primary">{props.nearbyHomeAvg + " Nearby Home Avg"}</Typography>
       <hr>
       </hr>
       <br></br>
       <div style={{fontSize: "15px",color:checkPositive(props.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</div>
         <Typography variant="body2" color="text.primary" >{props.similarHomeAvg + " Similar Home Avg"}</Typography>
         <hr>
       </hr>
       <br></br>
      
      
      </CardContent>

    </Card>
  );
}