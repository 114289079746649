
import React, { useState, useEffect, useCallback } from 'react';
import Header from "components/headers/light.js";
import {API_URL} from '../Constants'
import ReactGA from 'react-ga';
import { Dimmer, Loader } from "semantic-ui-react";
import SimpleSubscribeNewsletter from 'components/forms/SimpleSubscribeNewsletter';
import tw from "twin.macro";
import { width } from '@mui/system';
//import "./TableStyles.css"; 
const Container = tw.div`relative`;


const News = (props) => {
const [data, setData] = useState([]);
const [isLoading, setLoading] = useState(true);
const [active, setActive] = useState(true);
        
    
useEffect(() => {
    ReactGA.pageview("/News");
    //  ReactGA.pageview(window.location.pathname);
     // ReactGA.pageview(window.location.pathname + window.location.search);
      //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
      
      //let currentEmail = getCookie("email"); 
      
      const urltosend = API_URL + '/LoadData/ShowAllStories';
 
  
      fetch(urltosend)
        .then((response) => response.json())
        .then((json) => {setData(json)})
        .catch((error) => console.error(error))
        .finally(() => setActive(false));
   
      
  
        //setTotalPages(calculateTotalPages(data));
    }, []);
    return (
    
       <Container>
           <Header />
           <br>
  </br><div><SimpleSubscribeNewsletter/></div>
         <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Loading Top Real Estate News!</Loader>
    </Dimmer>
    </div> 
    <br></br><h1>Top Real Estate News From Around The Web</h1>
    <br></br>
        <table >

                <tr>
                    <th>Source</th>
                    <th>Title</th>
                    <th>Link</th>
                </tr>
        {data.map((data, index) => (
          <tr><th>{data.source}</th><th>{data.title}</th><th><a href={data.url} target='_blank'>Click To Read</a></th></tr>
                 
           
        ))};
    </table>
   

</Container>



    );

}
export default News;