import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";

const TaxChart = (props) => {
  const chartoptions = {
    series: [
      {
        name: "Tax History",
        data: props.data,
      },
     
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },

      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: 
          props.labels
        ,
      },
    },
  };
  return (
    <Card  >
      <CardBody>
        <CardTitle tag="h5">Tax History</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Available Tax Information 
        </CardSubtitle>
        <Chart
          type="area"
           
          height="390"
          options={chartoptions.options}
          series={chartoptions.series}
        ></Chart>
      </CardBody>
    </Card>
  );
};

export default TaxChart;
