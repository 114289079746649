
import React,{ useEffect,useState }  from 'react';
import './Marker.css';
import { Rating } from '@mui/material';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import "./Card2.css";
import Highlighter from "react-highlight-words";
const Marker = (props,$hover) => {
    const {  name, rating,sourceZpid,colorStyle,zpid,color } = props;
    const [myStyle , setMyStyle] = useState("2px solid red");
    if (color ==="blue") {
      console.log("Hit" + color);


    }else 
    {
      console.log("No Dice" );
    }
     
    const handleClick = () => {
      console.log(`You clicked on link`);
    };
   
     
    function checkPositive(num) {
  
      if (num<0){
        return false
      }else if (num>0) 
      {
        return true
      } 
    }
    function currencyFormat(num) {
      if(num)
      {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }else 
      {
        return 'Not Available'
      }
      
    }
    function buildPercentageString(num) {
  
      if (num<0){
        return num + '% below nearby home average. '
      }else if (num>0) 
      {
        return num + '% above nearby home average. '
      } 
    }
    const Styleitem =() =>
      {  
        if (color ==="blue") {
        return(
        <span className="marker"
        style={{backgroundColor: '#42f560',textAlign:'center',fontWeight: 'bold',fontsize:50,padding: 7,borderradius: '100%',color:"blue",border:'3px solid blue'  , cursor: 'pointer',width:50,height:50}}
        title={name} 
    ><div style={{ color: 'black', fontSize: 12,textAlign:"center" }} >{rating}<StarIcon  style={{ color: 'gold', fontSize: 15,fontWeight: 'bold' }}  fontSize="xs"   /></div>
    {props.$hover ? (<div style={{ backgroundColor: "White",textAlign:"center",fontWeight: 'bold',fontsize:25,padding: 4,borderradius: "100%",color: '#3f51b5',border: '5px solid black', cursor: 'pointer',width:300,height:500}}> <p>Our Potential Investment Rating ({rating} out of 10) </p>
        <Rating name="customized-10" value={rating} max={10} readOnly />            
        <img width="280" height="100" src={props.imgSrc}></img>
        <span ><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={["Property Status:", "REDUCED", "PENDING",props.homeStatus]}
        autoEscape={true}
        textToHighlight={"Property Status:  " + props.homeStatus}
        /><br></br> </span>
         <div> <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr></div>
        <span  className="lowpercentmap" >{props.priceAction}</span>
        <span><div style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Listed Price:" + currencyFormat(props.price) }</div></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>

        <hr></hr><span    className={checkPositive(props.priceIncreasePercentage) ? 'lowpercentmap' : 'highpercentmap' }>{buildPercentageString(props.priceIncreasePercentage)}</span>
        <span><br></br><div style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{currencyFormat(props.nearbyHomeAvg) + " Nearby Home Avg"}</div></span><br></br>		<span  ><hr></hr></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>
        <span  className={checkPositive(props.similarpriceincreasepercentage) ? 'lowpercentmap' : 'highpercentmap' }>{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</span> <span><br></br><div style={{ color: 'black', fontSize: 17,textAlign:"center" }}>{currencyFormat(props.similarHomeAvg) + " Similar Home Avg"}</div></span><br></br>		<span  ><hr></hr></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>
        <span  ><div  style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Estimated Rental Amount " + currencyFormat(props.rentZestimate)}</div></span>
        <span  ><div  style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Est. Gross Rental Yield " + props.grossRentalYield +"%"} </div></span>
        </div>):null}
       
    </span>
      );
  
  
      }else 
      {
        return (
        <span className="marker"
        style={{backgroundColor: 'white',textAlign:'center',fontWeight: 'bold',fontsize:50,padding: 7,borderradius: '100%',color:"red",border:'2px solid red' , cursor: 'pointer',width:40,height:40}}
        title={name} 
    ><div style={{ color: 'black', fontSize: 12,textAlign:"center" }} >{rating}<StarIcon  style={{ color: 'gold', fontSize: 15,fontWeight: 'bold' }}  fontSize="xs"   /></div>
    {props.$hover ? (<div style={{ backgroundColor: "White",textAlign:"center",fontWeight: 'bold',fontsize:25,padding: 4,borderradius: "100%",color: '#3f51b5',border: '5px solid black', cursor: 'pointer',width:300,height:500}}> <p>Our Potential Investment Rating ({rating} out of 10) </p>
        <Rating name="customized-10" value={rating} max={10} readOnly />            
        <img width="280" height="100" src={props.imgSrc}></img>
        <span ><Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={["Property Status:", "REDUCED", "PENDING",props.homeStatus]}
        autoEscape={true}
        textToHighlight={"Property Status:  " + props.homeStatus}
        /><br></br> </span>
         <div> <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr></div>
        <span  className="lowpercentmap" >{props.priceAction}</span>
        <span><div style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Listed Price:" + currencyFormat(props.price) }</div></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>

        <hr></hr><span    className={checkPositive(props.priceIncreasePercentage) ? 'lowpercentmap' : 'highpercentmap' }>{buildPercentageString(props.priceIncreasePercentage)}</span>
        <span><br></br><div style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{currencyFormat(props.nearbyHomeAvg) + " Nearby Home Avg"}</div></span><br></br>		<span  ><hr></hr></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>
        <span  className={checkPositive(props.similarpriceincreasepercentage) ? 'lowpercentmap' : 'highpercentmap' }>{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</span> <span><br></br><div style={{ color: 'black', fontSize: 17,textAlign:"center" }}>{currencyFormat(props.similarHomeAvg) + " Similar Home Avg"}</div></span><br></br>		<span  ><hr></hr></span>
        <hr style={{height:'1px',border:'solid',color:'#333',backgroundcolor:'#333'}}></hr>
        <span  ><div  style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Estimated Rental Amount " + currencyFormat(props.rentZestimate)}</div></span>
        <span  ><div  style={{ color: 'black', fontSize: 14,textAlign:"center" }}>{"Est. Gross Rental Yield " + props.grossRentalYield +"%"} </div></span>
        </div>):null}
       
    </span>
      );
      }

          
      }
    function buildSimilarPercentageString(num) {
      
      if (num<0){
        return num + '% below similar property average. '
      }else if (num>0) 
      {
        return num + '% above similar property average. '
      } 
    }
    
    const infowindow = new window.google.maps.InfoWindow({ content: props.name });
    return (
     <div >
 
      <Styleitem />
   </div>

    );
   
  };

  export default Marker;