import React, { useState, useEffect, useCallback } from 'react';
import tw from "twin.macro";
import {ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg"
import {Container as ContainerBase } from "components/misc/Layouts.js";
import {SectionHeading} from "components/misc/Headings.js";
import {PrimaryButton} from "components/misc/Buttons.js";
import {API_URL} from '../../Constants';
import JacobHeadShot from "../../images/JacobHeadShot.JPG";
import MilePostBanner from "../../images/AdBanners/MilePost.jpg";
import OnTheBlock from "../../images/AdBanners/OnTheBlock.png";
import JacobBanner from "../../images/AdBanners/JacobBanner.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// import Newsletterimage from "../../images/Newsletterimage.jpg";
// import Blah from '../images';
 
//import ProjectTables from "../components/dashboard/ProjectTable";
import {Modal, Button} from 'react-bootstrap';
import '../../legacy/ModalStyles.css';


const Container = tw(ContainerBase)`bg-secondary-100 -mx-6`
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-2 px-16 `;

const Row = tw.div`flex items-center justify-center flex-col md:flex-row px-12`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row px-12`
const FormColumn = tw.div`mt-6 lg:mt-0 lg:ml-5 w-full sm:w-auto`

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-green-500`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-100 font-medium text-lg max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200`
//const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`
const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-24 h-32 rounded-full`;
//const BannerImage = tw.img`max-w-lg  h-200  `;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`  text-sm`;

export default (props) => {


  const [subject, setSubject] = useState("Support Issue");
  const [recipientEmail, setRecipientEmail] = useState();
  const [message, setMessage] = useState( );
  const [emailValid, setEmailValid] = useState(true);
  const [lastName, setLastName] = useState();
  const [newsletter, setNewsletter] = useState(true);
  const [active, setActive] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [senderEmail, setSenderEmail] = useState();
  const [emailaddress, setEmailAddress] = useState();
  const [showModalVal, setShowModalVal] = useState(false);
  const ActionButton = tw(
    AnchorLink
  )`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
  const closeModal = (e) => {
    props.setShowTaxHistoryModal(false);
};
//https://www.facebook.com/profile.php?id=100075997896234
const handleClose = () => props.setShowTaxHistoryModal(false);
const handleShow = () => props.setShowTaxHistoryModal(true);
  async function emailSupport(shareinfo) {
    setActive(true);
    //const postData = { email: email ,password:password ,firstname:firstName, lastname:lastName,newletter:newsletter};
     // const emailstring = '/api/email/tempsubscribe?emailaddress=' + shareinfo.emailaddress;
     const emailstring = '/api/email/tempsubscribe';
     return fetch(API_URL + emailstring, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(shareinfo)
      })
        .then(data =>{ data.json(); setShowResults(true); setActive(false)})
     }
  

  function emailValidation(){
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!recipientEmail || regex.test(recipientEmail) === false){
        setEmailValid(false);
        return false;
    }
    setEmailValid(true);
    return true;
  }
  const handleSubmit = async e => {
    e.preventDefault();
   
 
      const token=  await emailSupport({
        emailaddress
      });
  

    }

    function showModal()
    {
      setShowModalVal(true);
    } 

    function showAdModal() 
    {
      console.log("tetkskdljss  jdlsjs "); 
      // window.location.href = "https://wwww.google.com?q=ABC";
      // setShowModalVal(true);
      window.open(
        'https://www.facebook.com/profile.php?id=100075997896234',
        '_blank' // <- This is what makes it open in a new window.
      );
    }
    function showHeardModal()
    {
      //https://heardontheblock.com/
       // window.location.href = "https://wwww.google.com?q=ABC";
      // setShowModalVal(true);
      window.open(
        'https://heardontheblock.com/',
        '_blank' // <- This is what makes it open in a new window.
      );
    }
    function showMPAdModal() 
    {
      console.log("tetkskdljss  jdlsjs "); 
      // window.location.href = "https://wwww.google.com?q=ABC";
      // setShowModalVal(true);
      window.open(
        'https://mileposthomeinspection.com/',
        '_blank' // <- This is what makes it open in a new window.
      );
    }
    function clickItem(e)
    {
      if (e===0)
      {
        showMPAdModal()

      }
      if (e===1)
      {
        showAdModal()

      }
      if (e===2) 
      {
        showHeardModal()
      }

    }
  return (
    <>

 <div className="slider-container">
 <Carousel  dynamicHeight={false} infiniteLoop={true} onClickItem={clickItem} interval={3500} autoPlay={true} showThumbs={false} showArrows={true} >

                <div>
                    <img src={MilePostBanner} />
                    
                </div>
                <div>
                    <img src={JacobBanner} />
                    
                </div>
                <div>
                    <img style={{height: 250, width:300 }}  src={OnTheBlock} />
                </div>
  
            </Carousel>
            </div>
 <br></br>

    </>
  );
};
