import React,{ useEffect,useState }  from 'react';
import {  useSearchParams } from 'react-router-dom';
import Text from 'react';
import GoogleMapReact from 'google-map-react';
import { Carousel } from 'react-bootstrap';
import Marker  from './Markers.js';
import { CropPortrait } from '@material-ui/icons';
import { color } from '@mui/system';
import { Dimmer, Loader } from "semantic-ui-react";
import {API_URL} from '../Constants'
import Container from '@mui/material/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Label } from 'semantic-ui-react'
import './ModalStyles.css';
import './modal.css';
import { Button } from 'semantic-ui-react'
import { Dropdown } from 'semantic-ui-react'
import AdBanner from "components/forms/AdBanner";
import Header from "components/headers/light.js";
import { Route, Routes, useNavigate } from 'react-router-dom';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
function currencyFormat(num) {
  if(num)
  {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }else 
  {
    return ' Not Available'
  }
  
}
const CityMapZpid = (gems) => {
  const navigate = useNavigate();

 

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  const [hover, setHover] = useState(false);
  const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
  const [orderBy, setOrderBy] = useState("ourRating");
  const [city,setCity]= useState(searchParams.get("city"));
  const [min,setMin]= useState(50000);
  const [max,setMax]= useState(950000);
  const [offSet,setOffSet]= useState(0); 
//   const [centerLat,setCenterLat]= useState(29.7560); 
//   const [centerLng,setCenterLng]= useState(-95.3573); 
  const [centerLat,setCenterLat]= useState(searchParams.get("lat")); 
  const [centerLng,setCenterLng]= useState(searchParams.get("longitude")); 
  console.log("getting lat/lng:"); 
  console.log(centerLat);
  console.log(centerLng);
  const [markerColor, setMarkerColor] = useState("red");
  const defaultProps = {
    height:50,
    center: {
      lat: searchParams.get("lat"),
      lng: searchParams.get("longitude")
    },
    zoom: 15
  };

  function setDefaultProps(gems) {
    // const mapCenterLat = gems.props.latitude;
    // const mapCenterLng = gems.props.longitude ;
   
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function checkPositive(num) {
    
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  const onMouseEnter = () =>
  { 
    setHover(true);
    console.log("Mouse Enter");
    
    console.log("Hover: " + hover);


  }
  const onMouseExit = () =>
  { 
    setHover(false);
    console.log("Mouse Exit");

    console.log("Hover: " + hover);
  }
  useEffect(() => {
  
    const encodedValue = encodeURIComponent(gems.zpid);
    setCenterLat(searchParams.get("lat")); 
    setCenterLng(searchParams.get("longitude"));
    // const [centerLat,setCenterLat]= useState(searchParams.get("lat")); 
    // const [centerLng,setCenterLng]= useState(searchParams.get("longitude")); 
    //fetch( API_URL + '/PropertyInfo/getCityMapInfo?city=' + city + '&propertytype=' + propertyType)
    fetch( API_URL + '/PropertyInfo/getCityMapInfo?city=' + searchParams.get("city") + '&propertytype=' + searchParams.get("propertytype"))
        .then((response) => response.json())
        .then((json) => {setData(json); setActive(false)})
        .catch((error) => console.error(error))
        .finally(() => setDefaultProps(gems));
    }, []);

    const InfoBox = (props) => {
        let googleMapLocation = "https://maps.google.com/?q=" + props.lat + ", " + props.lng
        let windowGoogleMap = `window.location= + ${googleMapLocation}`
      console.log(props)
      return (
        <div>
          test
        </div>
        )
      }
      function refreshData()
      {
        setActive(true);
        // if(city==='Austin')
        // {
        //   setCenterLat(30.266666);
        //   setCenterLng(-97.733330);
        // }
        // if(city==='Houston')
        // {
        //   setCenterLat(29.7560);
        //   setCenterLng(-95.3573);
        // }
        // setCenterLat(searchParams.get("latitude")); 
        // setCenterLng(searchParams.get("longitude")); 
        fetch( API_URL + '/PropertyInfo/getCityMapInfo?city=' + city + '&propertytype=' + propertyType)
            .then((response) => response.json())
            .then((json) => {setData(json); setActive(false)})
            .catch((error) => console.error(error))
            .finally(() => setDefaultProps(gems));

      } 
      const handleChangePropertyType = (e,dropdowninfo) => 
      {
        setPropertyType(dropdowninfo.value);
      }
      const handleChangeCityData = (e,dropdowninfo) => 
      {
       
        setCity(dropdowninfo.value);
      } 
   
      const propertyTypesListData = [
        { key: 'Houses', text: 'Single Family', value: 'SINGLE_FAMILY' },
        { key: 'MultiFamily', text: 'Multi-Family', value: 'MULTI_FAMILY' },
        { key: 'MultiFamily', text: 'Townhomes', value: 'TOWNHOUSE' },
        { key: 'MultiFamily', text: 'Condos', value: 'CONDO' },
      
      ]
      const orderByListData = [
        { key: 1, text: 'Investment Rating', value: 'ourRating' },
        { key: 2, text: 'Price Descending', value: 'Price' },
        { key: 3, text: 'Price Ascending', value: 'priceaccending' },
      ]
      const CityData=[
        { key: 1, text: 'Houston', value: "Houston" },
        { key: 2, text: 'Austin', value: "Austin" },
        { key: 3, text: 'Miami', value: "Miami" },
        { key: 4, text: 'San Francisco', value: "San Francisco" }
      ]
      const ClickAction = () =>
      { 
        
        console.log("Clicked");
       // navigate('/about', { replace: true });
        window.open('/about','_self', 'rel=noopener noreferrer')
    
    
      }

const _onChildClick = (key, childProps) => {
  console.log(childProps);
  ///Details?zpid=27812810&loadid=a986d533-1d45-4c60-a0b7-31b60fd59883
  var url="/PropDetails?zpid=" + childProps.zpid.trim() + "&loadID=" + childProps.loadID + "&eid=-2";
   window.open(url,'_blank', 'rel=noopener noreferrer')
 // this.props.onCenterChange([childProps.lat, childProps.lng]);
}
    return (  
      // Important! Always set the container height explicitly
      <>
      <div style={{height: '1000px', width: '100%'}}>
          {/* <div className='Loader'>
         
           <Loader active = {active} className='workaround' size='large' inline='centered' >Loading Map View!</Loader>
       
       </div> */}
          
 
       {/* <AdBanner ></AdBanner> */}
       {/* <Container><br></br>
       <Dropdown selection={propertyType} onChange={handleChangePropertyType} placeholder={propertyType}    options={propertyTypesListData} /><Dropdown  onChange={handleChangeCityData}    placeholder={city}   options={CityData} selection={city} /> 
      
      <Button primary  onClick={refreshData}>Refresh Map View</Button>
      </Container> */}
      <br></br>
      <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Loading Map View!</Loader>
    </Dimmer>
    </div> 
        <GoogleMapReact 
        //   lat={searchParams.get("lat")}
        //   lng={searchParams.get("longitude")}
          //defaultCenter={(centerLat, centerLng)}
          //onChange={e => setCenter(e.center)}
          onChildClick={_onChildClick}
          height={300}
          center={[parseFloat(centerLat), parseFloat(centerLng)]} 
          bootstrapURLKeys={{ key: 'AIzaSyCETi424NnsIH2Chp6XbmhGvHSRni6ok-w' }}
           defaultCenter={defaultProps.center}
           defaultZoom={defaultProps.zoom}
           //onChildMouseEnter={onMouseEnter}
        //onChildMouseLeave={onMouseExit}
         // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
        {data.map((gem2, index2) => (     
        //      <AnyReactComponent
        //      lat={59.955413}
        //      lng={30.337844}
        //      text={gem2.ourRating}
        //    />    

        // {hover ? (<InfoBox></InfoBox>):null}
          
          <Marker   
          key={index2}
            //   onClick={() => {
            //     ClickAction();
            // }}
            lat={gem2.latitude}
            lng={gem2.longitude}
            colorStyle={searchParams.get("zpid")===gem2.zpid.trim()?"'2px solid blue'":"'2px solid #f44336'"}
            color={searchParams.get("zpid")===gem2.zpid.trim()?"blue":"red"}
            sourceZpid={searchParams.get("zpid")}
            //color={markerColor} 
            loadID={gem2.loadID}
            zpid={gem2.zpid}
            rating={gem2.ourRating}
            imgSrc={gem2.imgSrc}
            priceIncreasePercentage={gem2.priceIncreasePercentage}
            similarpriceincreasepercentage={gem2.similarPriceincreasePercentage}
            nearbyHomeAvg={gem2.nearbyhomeavg}
            similarHomeAvg={gem2.similarHomeAvg}
            priceAction={gem2.priceAction}
            homeStatus={gem2.home_status}
            price={gem2.price}
            rentZestimate={gem2.rentZestimate}
            grossRentalYield={gem2.grossRentalYield}

          >
              

            </Marker>
             
          ))} 
          
          {/* <Marker
            lat={gems.props.latitude}
            lng={gems.props.longitude}
            color="blue"
          /> */}
        </GoogleMapReact>
        <br>
        </br>
       
 
    
      </div>
      </>
    );
  
}

export default CityMapZpid;