import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Investiocity</Subheading>}
        heading="We show what other sites don't"
        description="Tired of seeing all the same info on every single real eastate site these days? We show you numbers behind the property. Our algorithms are designed to scan all property listings and find only the top investments. We do the work so you don't have to. Just browse and enjoy."
       
        primaryButtonText="Browse Now"
        primaryButtonUrl="/investments"
        imageSrc="https://i.imgur.com/xvrsYUH.jpeg"
      />
      <MainFeature1
        subheading={<Subheading>SERVICES</Subheading>}
        heading="Real Estate Services"
        description="In addition to disrupting the idea of what a real estate site can tell you, we also offer a full range of real estate services. Questions on any of the properties you saw here? Interested in Buying or Renting? We are here to answer any questions you might have! "
 
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc="https://i.imgur.com/WCXHnpj.jpg"
        textOnLeft={false}
      />
            <MainFeature2
        subheading={<Subheading>MORE SERVICES</Subheading>}
        heading="Software and Web Development"
        description="In order to disrupt the idea of what a real estate site should show you, we needed a great team of designers and engineers. When we are not disrupting, we are developing. Investiocity can help you with anything from a basic website all the way up to an enterprise level application. Contact us today and let's talk about your needs."
 
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc="https://i.imgur.com/0Xfff2f.jpg"
     
      />
       
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We care about our clients"
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "We have people available 24/7 to assist with any real estate, development or site issues."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "As a team combined, we have hundreds of years of engineering and real estate expertise. Contact us to talk about your property or your project. "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "We will do whatever it takes to make sure you are a happy client. We aim to please!"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
