/* A simple iframe wrapper that makes 16:9 responsive embed */
import React, { useState, useEffect, useCallback } from 'react';
import {API_URL} from '../Constants'
import tw from "twin.macro"; 
import { Dimmer, Loader } from "semantic-ui-react";
import Chart from "react-apexcharts";
import Container from '@mui/material/Container';
export default ({ gems, background="white"}) => {

  const ModalHeader = tw.p`text-3xl text-primary-500 font-bold mb-5`;
   
const chartDataArray = [];
const labelsArray = [];
 const [isLoading, setLoading] = useState(true);
 const [priceData, setpriceData] = useState([]);
 const [loadOnce,setLoadOnce] = useState(0); 
 
 const [active, setActive] = useState(true);

 


useEffect(() => {
    //const encodedValue = encodeURIComponent(zpid);
    //
    //fetch('https://propertygems.azurewebsites.net/PropertyInfo/getPriceInfo?zpid=' + gems.zpid)
    fetch(API_URL +  '/PropertyInfo/getPriceInfo?zpid=' + gems.zpid + '&loadid=' + gems.loadID)
    .then((response) => response.json())
    .then((json) => setpriceData(json),setLoadOnce(loadOnce +  1))
    .catch((error) => console.error(error))
    .finally(() => setActive(false));
    console.log(priceData);
   // window.dispatchEvent(new Event('resize'));

  
}, []);
if (priceData.length != labelsArray.length){
  // if (priceData.length===1 )
  // {
  //   chartDataArray.push(0);
  //   labelsArray.push('');
  // }
    priceData.map((priceinfo, index) => {
        console.log(priceinfo.price)
        //if(priceinfo.priceChangeRate>0)
        //{
            labelsArray.push(priceinfo.epochConvertedTime.slice(0,10));
            chartDataArray.push(priceinfo.price);

        //}
    });
 
}
const chartoptions = {
  series: [
    {
      name: "Price History",
      data: chartDataArray,
    },
   
  ],
  options: {
    chart: {
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
        animationEnd: (chart) => {
          chart.windowResizeHandler();
        }
      },
      id: 'mychart',
      redrawOnParentResize: true,
      type: "bar",
      offsetX: 0,
      width:'50%',
      height:'50%'
    },
   
    zoom: {
      enabled: true,
    },
    dataLabels: {
      enabled: true,
    },
    grid: {
      strokeDashArray: 3,
    },
  


    stroke: {
      curve: "smooth",
      width: 1,
    },
    responsive: [{
      breakpoint: undefined,
      options: {},
  }],
    xaxis: {
      categories: 
      labelsArray
      ,
    },
  },
};
 
  return (
    <Container> 
          
    <div className='Loader'>
    <Dimmer
  active = {active}
  page={true}  size='massive' inverted> 
 <Loader inverted>Loading Price History</Loader>
</Dimmer>
</div> 
<ModalHeader>Available Price History</ModalHeader>
    <Chart 
    type="area"  
    height="390"
    options={chartoptions.options}
    series={chartoptions.series}
  ></Chart>
</Container>
  );
};
