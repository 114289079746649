import React, { useState } from 'react';
import tw from "twin.macro";
import { CircularProgress } from '@mui/material';
import { ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import { API_URL } from '../../Constants';
import Newsletterimage from "../../images/Newsletterimage.jpg";
import { Dropdown, Input } from 'semantic-ui-react';
//import './Subscribe.css';
const Container = tw(ContainerBase)`bg-secondary-600 -mx-4 relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-10`;
const Row = tw.div`flex items-center justify-center flex-col md:flex-row px-2`;
const FormColumn = tw.div`mt-6 lg:mt-0 lg:ml-5 w-full sm:w-auto`;
const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-green-500`;
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`;
const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`;
const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`;
const ThankYouMessage = tw.div`absolute bottom-0 w-full text-center py-4 text-green-500 text-lg font-bold`;

const NewsletterForm = () => {
  const [emailaddress, setEmailAddress] = useState("");
  const [city, setCity] = useState("Select City For Newsletter");
  const [emailNotValid, setEmailNotValid] = useState(false);
  const [cityNotValid, setCityNotValid] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeCityData = (e, { value }) => {
    setCity(value);
  };

  async function emailSupport(shareinfo) {
    const email = `/api/email/tempsubscribe?emailaddress=${shareinfo.emailaddress}&city=${city}`;
    try {
      const response = await fetch(API_URL + email, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(shareinfo),
      });
      const data = await response.json();
      setShowResults(true);
      setLoading(false); // Stop loading
    } catch (error) {
      console.error('Error:', error);
      setLoading(false); // Stop loading
    }
  }

  function emailValidation() {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    if (!emailaddress || !regex.test(emailaddress)) {
      setEmailNotValid(true);
      return false;
    }
    setEmailNotValid(false);
    return true;
  }

  const CityData = [
    { key: 1, text: 'Houston', value: "Houston" },
    { key: 2, text: 'Austin', value: "Austin" },
    { key: 3, text: 'Miami', value: "Miami" },
    { key: 4, text: 'San Francisco', value: "San Francisco" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailValidation()) {
      return false;
    }
    if (city === "Select City For Newsletter") {
      setCityNotValid(true);
      return false;
    } else {
      setCityNotValid(false);
    }

    setLoading(true); // Start loading

    await emailSupport({ emailaddress, city });
    localStorage.setItem('InvestiocityReturningUser', true);
    localStorage.setItem('InvestiocityEmail', emailaddress);
  };

  return (
    <Container>
      <Content>
        <Row>
          <Form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <Heading>Subscribe to our FREE daily Newsletter</Heading>
              <p style={{ fontFamily: "Courier New", color: "white", fontSize: "18px", textAlign: 'center' }}>
                Subscribe now to get TOP RANKED investments and their stats delivered directly to your inbox the same day it's listed!!
              </p>
              <img src={Newsletterimage} alt="Newsletter" style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }} />
              <div style={{ width: '100%', maxWidth: '500px', marginBottom: '20px' }}>
                <p style={{ fontFamily: "Courier New", color: "white" }}>Enter Email Address:</p>
                <Input name="emailaddress" type="email" placeholder="Enter Email Address" onChange={e => setEmailAddress(e.target.value)} fluid />
                {emailNotValid && <p style={{ fontWeight: "bold", fontSize: "15px", color: "red" }}>Enter Email</p>}
              </div>
              <div style={{ width: '100%', maxWidth: '500px', marginBottom: '20px' }}>
                <p style={{ fontFamily: "Courier New", color: "white" }}>What City Do You Want?</p>
                <Dropdown onChange={handleChangeCityData} placeholder={city} options={CityData} selection fluid />
                {cityNotValid && <p style={{ fontWeight: "bold", fontSize: "15px", color: "red" }}>Select A City</p>}
              </div>
              <Button type="submit" style={{ width: '100%', maxWidth: '500px' }}>
                {loading ? <CircularProgress size={24} /> : "Subscribe Now"}
              </Button>
            </div>
          </Form>
        </Row>
        {showResults && (
          <>
            <br />
            <br />
            <ThankYouMessage>
              Thank you for subscribing. Please check your spam folder if you do not see a welcome email from us!
            </ThankYouMessage>
          </>
        )}
      </Content>
    </Container>
  );
};

export default NewsletterForm;
