import React from 'react';
import './Learn.css'; // Import the CSS file
//import './App.css'; // Also import App.css if needed

function MainHeader() {
  return (
    <header className="header">
      <h1>Professional Development Program</h1>
    </header>
  );
}

function SubHeader() {
  return (
    <header className="sub-header">
      <h2>Program Details</h2>
    </header>
  );
}

function Learn2() {
  return (
    <div className="app">
      <MainHeader />
      <div className="content">
      <div className="space-odyssey">
        <h3>A space odyssey awaits you.</h3>
        <p>Beautiful space images and information of your current sky map location on every new tab.</p>
        </div>

        <div className="video-container">
          <video className="video" controls>
            <source src="your-video-source.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="overlay">
          <div className="overlay-content">
            <h3>Engineering Career Acceleration Program</h3>
            <p className="program-description">
              The Engineering Career Acceleration Program is designed for recent graduates and career changers looking to break into the tech industry. We provide comprehensive training and personalized mentorship to help you launch your career in software engineering.
            </p>
            <SubHeader />
            <ul className="list">
              <li>Intensive coding bootcamp with hands-on projects</li>
              <li>Personalized mentorship from industry professionals</li>
              <li>Portfolio development and job placement assistance</li>
              <li>Networking opportunities with leading tech companies</li>
              <li>Lifetime access to our alumni network and resources</li>
            </ul>
            <p className="limited-space">Limited Space Available</p>
            <p style={{ textAlign: 'center' }}>
              <a href="https://www.paypal.com/ncp/payment/CD2TQXT36AR8N" target="_blank" rel="noopener noreferrer" className="button">SIGN UP NOW</a>
            </p>
            <p style={{ textAlign: 'center' }}>
              <a href="mailto:learning@investiocity.com" className="email-link">Questions? Email Here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Learn2;
