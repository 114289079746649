import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";
import React, { useEffect, useState ,useRef } from "react";
 
import Container from '@mui/material/Container';
import {API_URL} from '../Constants';
import tw from "twin.macro"; 
import { Dimmer, Loader } from "semantic-ui-react";


const ResponsiveTaxEmbed = ({  gems }) => {
    const ModalHeader = tw.p`text-3xl text-primary-500 font-bold mb-5`;

    const chartDataArray = [];
    const labelsArray = [];
     const [isLoading, setLoading] = useState(true);
     const [loadOnce,setLoadOnce] = useState(0); 
     const [taxData, settaxData] = useState([]);
     const count =0 ; 
     const modalRef = useRef(); 
 
     const [active, setActive] = useState(true);
   
     useEffect(() => {
       //const encodedValue = encodeURIComponent(zpid);
       fetch(API_URL +  '/PropertyInfo/getTaxHistory?zpid=' + gems.zpid  + '&loadid=' + gems.loadID)
       .then((response) => response.json())
       .then((json) => settaxData(json))
       .catch((error) => console.error(error))
       .finally(() => setActive(false));
       
 
       
       
   }, []);
   if (taxData.length != labelsArray.length){
       taxData.map((taxinfo, index) => {
           console.log(taxinfo.price)
           //if(priceinfo.priceChangeRate>0)
           //{
               labelsArray.push(taxinfo.convertedTime.slice(0, -10));
               chartDataArray.push(taxinfo.taxPaid);
       
           //}
       });
   }
   const chartoptions = {
    series: [
      {
        name: "Tax History",
        data: chartDataArray,
      },
     
    ],
    options: {
      chart: {
        events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            },
            animationEnd: (chart) => {
              chart.windowResizeHandler();
            }
          },
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },

      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        categories: 
        labelsArray,
      },
    },
  };
    return (
        <Container> 
          
        <div className='Loader'>
        <Dimmer
      active = {active}
      page={true}  size='massive' inverted> 
     <Loader inverted>Loading Tax History</Loader>
    </Dimmer>
    </div> 
    <ModalHeader>Available Tax History</ModalHeader>
          <Chart
            type="area"
             
            height="390"
            options={chartoptions.options}
            series={chartoptions.series}
          ></Chart>
      
</Container>
    );
  };
  
  export default ResponsiveTaxEmbed;
  