import React, { useState, useEffect } from 'react';
import {API_URL} from '../Constants'
import {  useSearchParams } from 'react-router-dom'
import DetailsNew2 from './DetailsNew2';
import { Dimmer, Loader } from "semantic-ui-react";

const DetailsWrapper= () => {
    const [searchParams, setSearchParams] =useSearchParams(); 
    // const q = searchParams.get('test')
     const [zpid, setZpid] = useState(searchParams.get('zpid'));
     const [eid, setEid] = useState(searchParams.get('eid'));
  
     const [loadID, setLoadID] = useState(searchParams.get('loadID'));
     const [isLoading, setLoading] = useState(true);
     const [data, setData] = useState([]);
     const [emailaddress,setEmailAddress] = useState(localStorage.getItem('InvestiocityEmail') );
  
     const [active, setActive] = useState(true);
useEffect(() => {
         
    //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
    //let currentEmail = getCookie("email");
    
   const urltosend = API_URL + '/Display/GetDetails?zpid=' + zpid + '&loadid=' + loadID + '&eid=' + eid + '&emailaddress=' + emailaddress ;

    fetch(urltosend)
      .then((response) => response.json())
      .then((json) => {setData(json.model); setActive(false)})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

      //setTotalPages(calculateTotalPages(data));
  }, []);
  function currencyFormat(num) {
    if(num)
    {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }else 
    {
      return 'Not Available'
    }
    
  }
  function createMapUrl(lat,lng,zpid,propertytype,city)
  {
    var url = "/CityMapZpid?lat=" + lat + "&longitude=" + lng + "&propertytype=" + propertytype + "&zpid=" + zpid.trim() + "&city=" + city 
    return url; 



  }
  return (
    <>
             <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Getting Property Details!</Loader>
    </Dimmer>
    </div> 
    {data.map((card, index) => (
        <DetailsNew2 
        id = {index}
                image={card.imgSrc}
                price={currencyFormat(card.price)} 
                shortdesc={card.shortdesc}
                description={card.description}
                address={card.address}
                priceIncreasePercentage = {card.priceIncreasePercentage} 
                grossRentalYield={card.grossRentalYield }
                mapUrl={createMapUrl(card.latitude,card.longitude,card.zpid,card.propertytype,card.city)}               
                color="primary"
                zpid = {card.zpid}
                nearbyHomeAvg={currencyFormat(card.nearbyHomeAvg)}
                imageList = {card.imageList}
                ourRating  ={card.ourRating}
                communityRating = {card.communityRating}
                rentZestimate = {currencyFormat(card.rentZestimate)} 
                similarHomeAvg = {currencyFormat(card.similarHomeAvg)}
                loadID = {card.loadId}
                similarpriceincreasepercentage= {card.similarpriceincreasepercentage}
                isBookMarked={card.isBookMarked}
                latitude={card.latitude}
                longitude={card.longitude}
                mls = {card.mls}
                isNew={card.isNew}
                timeOnZillow={card.timeOnZillow}
                totalSqrFt = {card.totalSqrFt}
                pricePerSqrFt = {card.pricePerSqrFt}
                schoolRatingAvg = {card.schoolRatingAvg}
                home_status = {card.home_status}
                priceAction = {card.priceAction}
                investmentDecisionReason= {card.investmentDecisionReason}
  ></DetailsNew2>

 

    ))};
    </>
  );
}
export default DetailsWrapper; 