import React from 'react';
import './Learn.css'; // Import the CSS file 
import Header from "components/headers/light.js";
function MainHeader() {
  return (
    <div>
    <header className="header">
      <h1>12 Week Professional Software Engineering 1-1 Mentorship!</h1>
      <br/>

    </header>
    <header className="header1">
        <h2>The ONLY course you will get REAL WORLD DOT COM experience!</h2>
        <br/>
        
  
      </header>
      <p style={{ textAlign: 'center' }} className="limited-space">CHANGE YOUR LIFE TODAY!</p>
          <br></br>
        <p style={{ textAlign: 'center' }}  className="program-description"> 
        My 1 on 1 Software Engineering mentorship is unlike any 
        other opportunity to learn to become a Software Engineer.
        No where else will you learn WHILE getting REAL WORLD 
        EXPERIENCE by working with a .COM startup founder. You'll 
        receive 3 hours a week of 1 on 1 personalized coaching, 
        work on real-world projects, learn how a development team 
        works, receive mentorship from a 25 year industry veteran 
        and I will continue to prep you for every interview until 
        you land a job.
          </p>
          <br></br>
          
      </div>
  );
}

function SubHeader() {
  return (
    <header className="sub-header">
      <h2>Overview</h2>
    </header>
  );
}
function SubHeader2() {
  return (
    <header className="sub-header">
      <h2>What You'll Learn</h2>
    </header>
  );
}

function App() {
  return (
    <div className="app">
      <Header />
      <br></br>
      <MainHeader />
      <div className="content">
        <div className="left-column">
       
          <br></br>
          <SubHeader />
          <ul className="list">
            <li>1-on-1 coaching by 25 year industry expert!</li>
            <li>Hands-on experience with real startup company code</li>
            <li>Personalized mentoring and career guidance</li>
            <li>Letter of recommendation from me upon completion</li>
            <li>ACTUAL Dot-Com startup experience on your resume!</li>
            <li>Continuous interview prep and support UNTIL you land a job</li>
            <li>THE MOST legitimate course you will find! No bootcamp, just real world startup experience! </li>
            <li>This will make you INFINITELY more valuable than any online course! </li>
            
          </ul>

          <br></br>
          <SubHeader2 />
          <ul className="list">
            <li>C# Backend Coding</li>
            <li>SQL Database Design And Queries</li>
            <li>Azure Cloud Development</li>
            <li>Deep Knowledge of Algorithms</li>
            <li>How Dev Teams Actually Operate</li>
            <li>What Agile Development Is</li>
            <li>Source Code Control</li>
            <li>How To Land A Job Making Up To 6 Figures </li>
            <li>Loops And Data Structures </li>
            <li>Much, Much More! </li>
            
          </ul>
        </div>
        <div className="right-column">
        <div className="video-container">
          <br></br>
          <br></br>
          <br></br>
          <video className="video" controls width="70%">
            <source src="https://investiocitybanners.blob.core.windows.net/bannercontainer/MentoringAd.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          </div>
          <br />
          <br />
          <p style={{ textAlign: 'center' }} className="limited-space">Limited Space Available</p>
          <br></br>
          <p style={{ textAlign: 'center' }} className="limited-space">Only 10 Spots!</p>
          <br></br>
          <p style={{ textAlign: 'center' }}>
            <a href="https://www.paypal.com/ncp/payment/QGBECP7KV2WDQ" target="_blank" rel="noopener noreferrer" className="button">SIGN UP NOW</a>
          </p>
          <br></br>
           <p style={{ textAlign: 'center' }}>
    <a href="mailto:learning@investiocity.com" className="email-link">Questions? Email Learning@investiocity.com</a>
  </p>
        </div>
      </div>
    </div>
  );
}

export default App;
