import React, { useState, useEffect } from 'react';
import DetailsCard from './StyledPropCard/DetailsCard';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import Header from "components/headers/light.js";
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CompsModal from '../legacy/CompsModal';
import Nearby from "../legacy/Nearby"; 
import ResponsiveHistoryEmbed from "../helpers/ResponsiveHistoryEmbed.js";
import ResponsiveNearbyEmbed from "../helpers/ResponsiveNearbyEmbed.js";
import ResponsiveTaxEmbed from "../helpers/ResponsiveTaxEmbed.js";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {  useSearchParams } from 'react-router-dom'
import {API_URL} from '../Constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  
  
const DetailsNew2= (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
    const [value, setValue] = React.useState(0);

    // const {state} = useLocation();
    // const {  props } = state; // Read values passed on state
    const theme = useTheme();

    
  const [searchParams, setSearchParams] =useSearchParams(); 
 
 

 
    function checkPositive(num) {
      
        if (num<0){
          return false
        }else if (num>0) 
        {
          return true
        } 
      }
      function checkZero(num){
        if(num>0) 
        {
          const val = "Gross Rental Yield " + num +  "%";
          return val;
        }else 
        {
          return "Gross Rental Yield Unavailable ";
        }
      }
      function buildSimilarPercentageString(num) {
            
        if (num<0){
          return num + '% below similar property average. '
        }else if (num>0) 
        {
          return num + '% above similar property average. '
        } 
      }
      function buildPercentageString(num) {
      
        if (num<0){
          return num + '% below nearby home average. '
        }else if (num>0) 
        {
          return num + '% above nearby home average. '
        } 
      }
      function getPriceAction(priceaction){
        //<p  style={{color:"red", align:"center",fontSize: "15px", display: 'inline' }}>JUST LISTED</p> <p style={{color:"red", align:"center",fontSize: "15px", display: (props.priceAction===null||props.priceAction===undefined|| props.priceAction==='')? 'none'  : 'inline'}}></p><p     style={{color:"red", align:"center",fontSize: "15px", display: props.priceAction==="REDUCED" ? 'none' : 'inline'}}>REDUCED</p>
        var returnPriceAction="     ";
        if(props.isNew){
            returnPriceAction = "JUST LISTED";
        }else if(props.priceAction!=null) {
            returnPriceAction=props.priceAction;
        }else {
            returnPriceAction=" ";
        }
        return returnPriceAction;
    
    
    
      }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
    return (
        <Container maxWidth="xl">

        <Header />  
        <br></br> <br></br>    
    <Grid container spacing={4}>
          
  
      <Grid item lg={3} xs={12} >
        {/* <ShowAd index={parseInt(index)}></ShowAd> */}
 
        <DetailsCard 
           
          image={props.image}
          price={props.price} 
          shortdesc={props.shortdesc}
          description={props.description}
          address={props.address}
          priceIncreasePercentage = {props.priceIncreasePercentage} 
          grossRentalYield={props.grossRentalYield }
          mapUrl={props.mapUrl}
          color="primary"
          zpid = {props.zpid}
          nearbyHomeAvg={props.nearbyHomeAvg}
          imageList = {props.imageList}
          ourRating  ={props.ourRating}
          communityRating = {props.communityRating}
          rentZestimate = {props.rentZestimate} 
          similarHomeAvg = {props.similarHomeAvg}
          loadID = {props.loadId}
          similarpriceincreasepercentage= {props.similarpriceincreasepercentage}
          isBookMarked={props.isBookMarked}
          latitude={props.latitude}
          longitude={props.longitude}
          mls = {props.mls}
          isNew={props.isNew}
          totalSqrFt = {props.totalSqrFt}
          pricePerSqrFt = {props.pricePerSqrFt}
          schoolRatingAvg = {props.schoolRatingAvg}
          home_status = {props.home_status}
          priceAction = {props.priceAction}
          investmentDecisionReason= {props.investmentDecisionReason}
          timeOnZillow={props.timeOnZillow}
          bedrooms={props.bedrooms}
          over90={props.over90}
        /> 
       </Grid>
       <Grid item lg={3} xs={12} >
        {/* <ShowAd index={parseInt(index)}></ShowAd> */}
        <Box sx={{ bgcolor: 'background.paper', width: 850 }}>
     
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
          aria-label="full width tabs example"
        >
           <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Comps" {...a11yProps(1)} />
          <Tab label="Neighbors" {...a11yProps(2)} />
          <Tab label="Price History" {...a11yProps(3)} />
          <Tab label="Tax History" {...a11yProps(4)} />
          <Tab label="Ratings Map" {...a11yProps(5)} />
        </Tabs>
    
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Card sx={{ maxWidth: 600 }}>

    


  <p  style={{color:"red", align:"center",fontSize: "20px", display: 'inline' }}>{getPriceAction("test")}  </p><p  style={{color:"white", align:"center",fontSize: "15px", display: 'inline' }}>placeholder </p>
   
               
   <p style={{fontSize: "16px",color:'black' }} >{  props.address}</p>
   <hr>
   </hr>
{/* <CardMedia
  component="img"
  height="194"
  image={props.image}
  alt="Image Not Available"
/> */}
         <CardContent>
{/* <div style={{fontSize: "13px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="large" >{props.address}</div> */}
<div style={{fontSize: "20px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="Medium" >LISTED PRICE: {props.price } </div>      
<div     style={{fontSize: "15px"}}> 
                      Days On Market: {props.timeOnZillow}
                       </div>
                      <div     style={{align:"center",fontSize: "15px"}}> {"Property Status:  " + props.home_status}</div>
                    
                    <br></br>
<div style={{fontSize: "20px",color:checkPositive(props.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(props.priceIncreasePercentage)}</div>
   <Typography variant="body1" color="text.primary">{props.nearbyHomeAvg + " Nearby Home Avg"}</Typography>
 <hr>
 </hr>
 <br></br>
 <div style={{fontSize: "20px",color:checkPositive(props.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</div>
   <Typography variant="body1" color="text.primary" >{props.similarHomeAvg + " Similar Home Avg"}</Typography>
   <hr>
 </hr>
 <br></br>
 <div style={{fontSize: "15px",color:'black' }} >{"Estimated Rental Amount " + props.rentZestimate}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{checkZero(props.grossRentalYield)} </div>

                         <hr style={{color:'black',borderWidth:3}}></hr>
<br></br>
                        <div style={{fontSize: "15px",color:'black' }} >{"Total Square Feet: " + props.totalSqrFt}</div>

                        <div style={{fontSize: "15px",color:'black' }} >{"Price Per Square Feet " + props.pricePerSqrFt}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{"School Rating Avg(1-10) : " + props.schoolRatingAvg} </div>
                        <div style={{fontSize: "15px",color:'black' }} >{" MLS #: " +  props.mls } </div>
                        <div style={{fontSize: "15px",color:'black' }} >{"Bedrooms: " + props.bedrooms}</div>

                         <hr style={{color:'black',borderWidth:3}}></hr>
 <br></br>
 <a target="_blank" href={"https://www.zillow.com/homes/for_sale/" + props.zpid.trim() + "_zpid/"} target="_blank"><Button variant="outlined" >View Full Listing</Button></a> 
 <br></br>
 <br></br>
 {/* <CardActions  onClick={toggleEmailModal} style={{ bottom:0}} >Email This Property To Yourself</CardActions> */}
                   
                   <Typography variant="body1" color="text.secondary">
    {props.description}
  </Typography>
  <br></br>


</CardContent>
</Card>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <CompsModal setShowCompsModal='true' gems={props} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <ResponsiveNearbyEmbed gems={props}   />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
        <ResponsiveHistoryEmbed gems={props}   />
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
        <ResponsiveTaxEmbed gems={props}   />
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
        <iframe id="iframe1" height="600" width="100%"   allowfullscreen  src={props.mapUrl}/>
        </TabPanel>
      </SwipeableViews>
    </Box>
      
       </Grid>
    </Grid>

    </Container>
  );
}

export default DetailsNew2