
import React, { useState, useEffect, useCallback } from 'react';
 
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Newsletterpictures from "components/forms/Newsletterpictures.js";
import Mapfeature from "components/forms/Mapfeature.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ReactGA from 'react-ga';
import {Routes, Route, useNavigate} from 'react-router-dom';

import Testimonial2 from "components/testimonials/TwoColumnWithImage";
import AdBanner from "components/forms/AdBanner";

export default () => {
  const navigate = useNavigate();
  useEffect(() => { 
    navigate('/investments');
    ReactGA.pageview(window.location.pathname);

  
        //setTotalPages(calculateTotalPages(data));
    }, []);

    
    return(

      <AnimationRevealPage>
        <Hero />
        <br></br> 
        <br></br> 
        <SubscribeNewsLetterForm />
        < Mapfeature />
        < Newsletterpictures/>
        {/* <SliderCard />
        <TrendingCard /> */}
        <MainFeature />
        {/* <Testimonial2 /> */}
        {/* <Testimonial textOnLeft={true}/> */}
        {/* <FAQ /> */}
        <SubscribeNewsLetterForm />
        <Footer />
      </AnimationRevealPage>
    );
};