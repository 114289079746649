import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './DetailsNew.css'; // You may need to create this file for styling
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Header from "components/headers/light.js";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CompsModal from '../legacy/CompsModal';
import Nearby from "../legacy/Nearby"; 
import ResponsiveHistoryEmbed from "../helpers/ResponsiveHistoryEmbed.js";
import ResponsiveNearbyEmbed from "../helpers/ResponsiveNearbyEmbed.js";
import ResponsiveTaxEmbed from "../helpers/ResponsiveTaxEmbed.js";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import StarRatings from 'react-star-ratings'; 
import Tooltip  from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const DetailsNew= () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function checkZero(num){
    if(num>0) 
    {
      const val = "Gross Rental Yield " + num +  "%";
      return val;
    }else 
    {
      return "Gross Rental Yield Unavailable ";
    }
  }
  function buildSimilarPercentageString(num) {
        
    if (num<0){
      return num + '% below similar property average. '
    }else if (num>0) 
    {
      return num + '% above similar property average. '
    } 
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function getPriceAction(priceaction){
    //<p  style={{color:"red", align:"center",fontSize: "15px", display: 'inline' }}>JUST LISTED</p> <p style={{color:"red", align:"center",fontSize: "15px", display: (props.priceAction===null||props.priceAction===undefined|| props.priceAction==='')? 'none'  : 'inline'}}></p><p     style={{color:"red", align:"center",fontSize: "15px", display: props.priceAction==="REDUCED" ? 'none' : 'inline'}}>REDUCED</p>
    var returnPriceAction="     ";
    if(props.isNew){
        returnPriceAction = "JUST LISTED";
    }else if(props.priceAction!=null) {
        returnPriceAction=props.priceAction;
    }else {
        returnPriceAction=" ";
    }
    return returnPriceAction;



  }
  function checkPositive(num) {
      
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  const {state} = useLocation();
  const { props } = state; // Read values passed on state
   
  return (
    <Container maxWidth="xl">

    <Header />  
    <br></br> <br></br>
<div className="container">

  <div className="column1">
    <div className="row">
      <div className="flexbox">
      
              <Carousel width={1100} dynamicHeight={true} thumbWidth={100} showThumbs={false}   autoPlay infiniteLoop='true'>
      {
        props.imageList.map( image => {
          return <div>
            <img src={ image.url } />
          
          </div>
        })
      }
    </Carousel>
            
            </div> {/* Flexbox within Row 1 of Column 1 */}
    </div>
    <div className="row">
    <Box sx={{ bgcolor: 'background.paper', width: 1100 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Comparisons" {...a11yProps(0)} />
          <Tab label="Neighbors" {...a11yProps(1)} />
          <Tab label="Price History" {...a11yProps(2)} />
          <Tab label="Tax History" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <CompsModal setShowCompsModal='true' gems={props} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
        <ResponsiveNearbyEmbed gems={props}   />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <ResponsiveHistoryEmbed gems={props}   />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
        <ResponsiveTaxEmbed gems={props}   />
        </TabPanel>
      </SwipeableViews>
    </Box>
      
    </div>
  
  </div>
  <div className="column2">
    <div className="row">
    <Card sx={{ maxWidth: 345 }}>

    
<StarRatings  rating={props.ourRating} starRatedColor="#f5cb42" numberOfStars={10} name='rating' starDimension='20px'  starSpacing="2px" /><Typography style={{fontSize: "13px"}}> {     props.ourRating} out of 10</Typography>
<Tooltip  title={<p style={{ fontSize: "15px", color:"White" }}>{props.investmentDecisionReason} </p>}>
                <p style={{fontSize: "15px"}}>Why is it ranked this way?<IconButton><HelpOutlineIcon />    </IconButton></p>
                </Tooltip> 

  <p  style={{color:"red", align:"center",fontSize: "20px", display: 'inline' }}>{getPriceAction("test")}  </p><p  style={{color:"white", align:"center",fontSize: "15px", display: 'inline' }}>placeholder </p>
   
               
   <p style={{fontSize: "16px",color:'black' }} >{  props.address}</p>
   <hr>
   </hr>
{/* <CardMedia
  component="img"
  height="194"
  image={props.image}
  alt="Image Not Available"
/> */}
         <CardContent>
{/* <div style={{fontSize: "13px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="large" >{props.address}</div> */}
<div style={{fontSize: "20px",color:checkPositive(props.priceIncreasePercentage) ? 'black' : 'black'}}   fontSize="Medium" >LISTED PRICE: {props.price } </div>      
<br></br>
<div style={{fontSize: "20px",color:checkPositive(props.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(props.priceIncreasePercentage)}</div>
   <Typography variant="body1" color="text.primary">{props.nearbyHomeAvg + " Nearby Home Avg"}</Typography>
 <hr>
 </hr>
 <br></br>
 <div style={{fontSize: "20px",color:checkPositive(props.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</div>
   <Typography variant="body1" color="text.primary" >{props.similarHomeAvg + " Similar Home Avg"}</Typography>
   <hr>
 </hr>
 <br></br>
 <div style={{fontSize: "15px",color:'black' }} >{"Estimated Rental Amount " + props.rentZestimate}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{checkZero(props.grossRentalYield)} </div>

                         <hr style={{color:'black',borderWidth:3}}></hr>
<br></br>
                        <div style={{fontSize: "15px",color:'black' }} >{"Total Square Feet: " + props.totalSqrFt}</div>

                        <div style={{fontSize: "15px",color:'black' }} >{"Price Per Square Feet " + props.pricePerSqrFt}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{"School Rating Avg(1-10) : " + props.schoolRatingAvg} </div>
                        <div style={{fontSize: "15px",color:'black' }} >{" MLS #: " +  props.mls } </div>
                        <div style={{fontSize: "15px",color:'black' }} >{"Bedrooms: " + props.bedrooms}</div>

                         <hr style={{color:'black',borderWidth:3}}></hr>
 <br></br>
 <a target="_blank" href={"https://www.zillow.com/homes/for_sale/" + props.zpid.trim() + "_zpid/"} target="_blank"><Button variant="outlined" >View Full Listing</Button></a> 
 <a href={props.mapUrl} target='_blank'> <Button variant="outlined" >View Ratings Map</Button></a> 
 <br></br>
 <br></br>
 {/* <CardActions  onClick={toggleEmailModal} style={{ bottom:0}} >Email This Property To Yourself</CardActions> */}
                   
                   <Typography variant="body1" color="text.secondary">
    {props.description}
  </Typography>
  <br></br>


</CardContent>
</Card>
    </div>
 
  </div>
</div>
</Container>

  );
}

export default DetailsNew;
