import React, { useState , useEffect} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { Link } from 'react-router-dom';
import { Dimmer, Loader } from "semantic-ui-react";
//import ReactGA from 'react-ga';
import {API_URL} from '../../Constants' 
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";

const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`align-bottom mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 `;
export default () => {
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  useEffect(() => {
   // ReactGA.pageview(window.location.pathname);
   // ReactGA.pageview(window.location.pathname + window.location.search);
    //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
    let currentEmail = getCookie("email");
    
    const urltosend = API_URL + '/Display/GetFeaturedProps'; 

    fetch(urltosend)
      .then((response) => response.json())
      .then((json) => {setData(json); setActive(false); setGemCount(json.gemCount[0].gemCount)})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
 
    

      //setTotalPages(calculateTotalPages(data));
  }, []);
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
  const [orderBy, setOrderBy] = useState("ourRating");
  const [city,setCity]= useState("Houston");
  const [min,setMin]= useState(50000);
  const [max,setMax]= useState(950000);
  const [offSet,setOffSet]= useState(0); 
  const [isLoading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [totalPages, setTotalPages] = useState();
  const [data, setData] = useState([]);
  const [gemCount, setGemCount] = useState();
  const [email, setEmail]= useState();  
  const [cardRowCount, setCardRowCount]= useState(0);  
  const [active, setActive] = useState(true);
  const [weeksCount,setWeeksCount] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  function checkPositive(num) {
      
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function buildSimilarPercentageString(num) {
    
    if (num<0){
      return num + '% below similar property average. '
    }else if (num>0) 
    {
      return num + '% above similar property average. '
    } 
  }

  function checkZero(num){
    if(num>0) 
    {
      const val = "Gross Rental Yield " + num +  "%";
      return val;
    }else 
    {
      return "Gross Rental Yield Unavailable ";
    }
  }
  return (
    <Container>
       <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Loading Featured Investments!</Loader>
    </Dimmer>
    </div> 
      <Content>
        <HeadingWithControl>
          <Heading>Featured Single Family Investments  </Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
        {data.map((gem, index) => (
            <Card key={index}>
              <CardImage imageSrc={gem.imgSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{gem.address}</Title>
                  <RatingsInfo>
                    <StarIcon />
                    <Rating>{gem.ourRating}</Rating>
                  </RatingsInfo>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{gem.city}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{currencyFormat(gem.price)}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description> 
                  <div style={{fontSize: "15px",color:checkPositive(gem.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(gem.priceIncreasePercentage)}</div>
                <div style={{fontSize: "15px",color:'black' }} >{currencyFormat(gem.nearbyHomeAvg) + " Nearby Home Avg"}</div>
                </Description>
                <Description>
                <div style={{fontSize: "15px",color:checkPositive(gem.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(gem.similarpriceincreasepercentage)}</div>
                          <div style={{fontSize: "15px",color:'black' }} >{currencyFormat(gem.similarHomeAvg) + " Similar Home Avg"}</div>
                </Description>
                <Description>{gem.description.substring(0,200) + ' ...'}</Description>
              </TextInfo>
              <div style={{align:"bottom"}}><Link to={"/Details" + '?zpid=' + gem.zpid.trim() + '&loadid=' + gem.loadId}><PrimaryButton   >Details</PrimaryButton></Link></div>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
