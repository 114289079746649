
import ImageCarousel, { ImageType } from "./image-carousel.tsx";

import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import React, { useRef } from "react";
import "../helpers/carouselstyles.css";
//Modal.js 
import Carousel from 'react-bootstrap/Carousel';
//import 'bootstrap/dist/css/bootstrap.css';
import {Modal, Button} from 'react-bootstrap';
import './ModalStyles.css';
//  import {
//   MDBCarousel,
//   MDBCarouselInner,
//   MDBCarouselItem,
//   MDBView,
//   MDBContainer
// } from 'mdbreact'
export const ImageModal = ({ setShowModal, gems }) => {
  // close the modal when clicking outside the modal.
  
   
  const closeModal = (e) => {
    setShowModal(false);

};
const handleClose = () => setShowModal(false);
const handleShow = () => setShowModal(true);
  return (
    <>
      <Button className="nextButton" onClick={{handleShow}}>
        Open Modal
      </Button>

      <Modal    show={setShowModal} onHide={handleClose} aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header >
          <Modal.Title  id="example-custom-modal-styling-title"> {gems.imageList.length + " Images Available"}</Modal.Title>
         
        </Modal.Header>
        <Modal.Body> 

        <Carousel fade>
      {gems.imageList.map((gem, index) => (
          
          <Carousel.Item>
          <img
            className="d-block w-100"
            src={gem.url}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>

      ))}
      </Carousel>

        </Modal.Body>
        <Modal.Footer>
          <Button className="mybutton"  style={{height: '45px', width : '80px'}} onClick={handleClose}>
            Close
          </Button>
  
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ImageModal; 