//Modal.js
import React, { useEffect, useState ,useRef } from "react";
import ReactDom from "react-dom"; 
//import 'bootstrap/dist/css/bootstrap.css';
import { Col, Row } from "reactstrap"; 
import Nearby from "./Nearby"; 
import './modal.css';
import {Modal, Button} from 'react-bootstrap';
 import './ModalStyles.css';
export const NearbyModal = ({ setShowNearbyModal, gems }) => {
 
   
  const closeModal = (e) => {
    setShowNearbyModal(false);

};
function buildPercentageString(num) {
  
  if (num<0){
    return num + '% below nearby home average. '
  }else if (num>0) 
  {
    return num + '% above nearby home average. '
  } 
}
function checkPositive(num) {
  
  if (num<0){
    return false
  }else if (num>0) 
  {
    return true
  } 
}
const handleClose = () => setShowNearbyModal(false);
const handleShow = () => setShowNearbyModal(true);
  return (
    <>
      <Button className="nextButton" onClick={{handleShow}}>
        Open Modal
      </Button>

      <Modal   scrollable="True"   fullscreen="True"   show={setShowNearbyModal} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>Nearby Home Pictures and Price Info</Modal.Title> 
        </Modal.Header>
        <Modal.Body>
       
         <Nearby props={gems}   /></Modal.Body>
        <Modal.Footer>
          <Button  className="mybutton"  style={{height: '45px', width : '80px'}}  onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default NearbyModal; 