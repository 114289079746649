import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some frequently asked questions investment homes that Investiocity would love to share with our loyal users. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = "https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  imageContain = false,
  imageShadow = true,
  faqs = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs = [
    {
      question: "How do I determine the value of investment property ?",
      answer:
        "Investiocity calculates the price of similar and nearby homes to give you a comparative analysis on the property you’re looking at. Our property ranking shows you the reason why we determined that investment to be deemed the way it is for you to take a deeper look. Investiocity gives you everything other real estate sites give you with a more in depth look at whether the property shown would be a smart investment or one that you would most likely want to pass up on."
    },
    {
      question: "Is the interest rate on an investment home higher than a primary residence ?",
      answer:
        "Yes, the interest rate on an investment home is higher than on a primary residence. Lenders usually consider investment homes to be a riskier investment due to the fact that mortgage bowers would tend to due away with an investment home before bailing on their primary residence. Due to this fact the interest rate on an investment home would be .50% .75% higher than a primary residence. As of October 31st, 2022 the fixed interest rate on a 30 year loan is 6% so expect your investment homes interest rate to be 6.50% or 6.75%."
    },
    {
      question: "Is there a way to put 3.5% down for an investment home instead of 20% ?",
      answer:
        "15%-20% down is the general rule of thumb for mortgage borrowers using a conventional loan! So, what’s a good work around to instead pay 3.5% down? FHA loans! FHA loans underwriting is not as strict as a conventional loan with, obviously, the amount needed at closing, but also credit score requirements. The Credit score requirement for an FHA loan is 580 and this allows you to purchase up to four units (fourplex). However, to be allowed this you must live in one of the units since FHA is a loan that requires the borrower to occupy the residence they are purchasing. You can still rent out the other units and have that pay for the principal payments as you pay off the loan."
    },
    {
      question: "Is getting an investment home hard??",
      answer:
        "Obtaining an investment home as a mortgage borrower with no prior ownership in a home can be difficult. There is no underlined rule, but mortgage lenders may give loans to a borrower for investment home if a property manager looks over the property. Again, this is not required but lenders feel safer with their investment having a property manager look over the property and issues that arise instead of a first-time home buyer with no experience. Look at this as an opportunity rather than a drag, having multiple investment properties with someone managing the property for you could reduce a lot of headaches, create entire passive income, and good to establish that relationship as the portfolio grows and there is more properties to manage."
    }
  ];

  if (!faqs || faqs.length === 0) faqs = defaultFaqs;

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image imageContain={imageContain} imageShadow={imageShadow} imageSrc={imageSrc} />
          </Column>
          <Column>
            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <FAQSContainer>
                {faqs.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex === index ? <MinusIcon /> : <PlusIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
