import {
  CardHeader,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  CardImgOverlay,
 
} from "reactstrap";
import { Link } from 'react-router-dom'
import React,{  useState } from 'react';
import "./Card2.css";
//import { Modal } from "../../views/Modal";
import { ImageModal } from "../legacy/imagemodal"
import { PriceHistoryModal } from "../legacy/PriceHistoryModal";
import { TaxHistoryModal } from "../legacy/TaxHistoryModal";
import { SharePropertyModal } from "../legacy/SharePropertyModal";
import NearbyModal  from "../legacy/NearbyModal";
import IconButton from "@material-ui/core/IconButton";
import Tooltip  from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
 
import { Rating } from '@mui/material';

import Typography from '@material-ui/core/Typography'
 
import { Icon } from 'semantic-ui-react'  
import LocalSeeIcon from '@material-ui/icons/LocalSee';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsIcon from '@material-ui/icons/Directions';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FiberNewTwoToneIcon from '@material-ui/icons/FiberNewOutlined';
import Highlighter from "react-highlight-words";
import {API_URL} from '../Constants'
//import Card from "./Card";
function buildPercentageString(num) {
  
  if (num<0){
    return num + '% below nearby home average. '
  }else if (num>0) 
  {
    return num + '% above nearby home average. '
  } 
}
function buildSimilarPercentageString(num) {
  
  if (num<0){
    return num + '% below similar property average. '
  }else if (num>0) 
  {
    return num + '% above similar property average. '
  } 
}
function someAction()
{
  return true; 
}
function checkPositive(num) {
  
  if (num<0){
    return false
  }else if (num>0) 
  {
    return true
  } 
}
function checkIsBookMarked(val)
{
  if (val=='Y'){
    return true
  }else {
    return false
  }
}
function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
 
// function toggleBookMark(props)
// { 
//   let email = getCookie("email");
//   const urltosendRefresh = 'https://localhost:49153/BookMark'
//   //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       zpid: props.zpid,
//       loadID: props.loadID,
//       bookMarkStatus: "For Sale",
//       bookMarkPrice: props.title,
//       email: email
//     })
// };
//   fetch(urltosendRefresh,requestOptions)
//     .then((response) => response.json())
//     .catch((error) => console.error(error)) 
// }



const yieldTooltip ="Gross rental yield refers to the gross yield produced by a rental property. It's simply a function of the rent collected over the course of a year expressed as a percentage of the property's market value. A rental property with a market value of $100,000 that generates $12,000 in rental income each year would have a gross rental yield of 12%. Gross rental yield is not reflective of the actual profits (if any) an investment property generates. It is only based on annual rental income. It doesnt take expenses such as property taxes, management fees, and other operating expenses into account. This would be the net rental yield. In fact, it is certainly possible for a property to have a strong gross rental yield and still produce poor cash flow for its owner.For this reason, gross rental yield can be useful for a quick comparison between two investment properties or to narrow down a list of potential investments. ";
const PropertyCard = (props) => {


  function toggleBookMark (zpid,props, index)  {
    //  data[index].isBookMarked = "Y";
    
   
    let email = getCookie("email");
    
    const urltosendRefresh = API_URL + '/BookMark/togglebookmark?email=' + encodeURIComponent(email) + '&zpid=' + encodeURIComponent( zpid)  + '';
    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      
  };
    fetch(urltosendRefresh,requestOptions) 
      .catch((error) => console.error(error)) 
      if (isChecked=='Y')
      {
        setIsChecked('N');
      }else 
      {
        setIsChecked('Y');
      }
      
  };


  const[isChecked,setIsChecked] = useState(props.isBookMarked);
  const[isNew, setIsNew] = useState(props.isNew);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const openPriceHistoryModal = () => {
    setShowPriceHistoryModal(true);
  };
  const [showTaxHistoryModal, setShowTaxHistoryModal] = useState(false);
  const openTaxHistoryModal = () => {
    setShowTaxHistoryModal(true);
  };
  const [showNearbyModal, setShowNearbyModal] = useState(false);
  const openNearbyModal = () => {
    setShowNearbyModal(true);
  };
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const openSharePropertyModal = () => {
    setShowSharePropertyModal(true);
  };
   //const [urlLink, setUrlLink] = useState()
const titleitem = '<h1 style={{ color: "lightblue" }}>title</h1>';

  return (
 
    <Card style={{height:"1300px"}}  class="h-600">
       <CardHeader  >
       
        {/* <div className="profile" >
         <span className="letter">P</span>
        </div> 
         <FiberNewTwoToneIcon fontSize="large"/>
        */}
        <div> 
        <span className="font-link"><FiberNewTwoToneIcon style={{color:"red", display: props.IsNew===false ? 'none' : 'block'}}   fontSize="large" />  Our Potential Investment Rating ({props.ourRating} out of 10) </span>
        <Rating name="customized-10" value={props.ourRating} max={10} readOnly />            

      <div  class="hdg-label-info">  
     <Tooltip  title={<div><h3 style={{ fontSize: "18px", color:"White" }}>{props.InvestmentDecisionReason} </h3></div>}>
         <label>Why is it ranked this way?<IconButton><HelpOutlineIcon />    </IconButton></label>
         </Tooltip> 
         </div>
         <div  class="hdg-label-info"> 
      <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Bookmark this property to add to your favorites and to receive updates when key indicators like price or status change. </h3>}>
         <Icon key={props.id}  viewBox="0 0 24 24" height="20" width="20" onClick= {() => { toggleBookMark(props.zpid,props,props.id);  }} circular  name={checkIsBookMarked(isChecked)?'inverted red heart fill':'color=#acb0ad heart outline'}   size='large' />
         </Tooltip> 
        {checkIsBookMarked(isChecked)?'Bookmarked!':'Bookmark This Property'} </div>
        <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={["Property Status:", "REDUCED", "PENDING",props.homeStatus]}
    autoEscape={true}
    textToHighlight={"Property Status:  " + props.homeStatus}
  /> 
        <CardTitle tag="h6"> <span className="lowpercent">{ props.priceAction}</span></CardTitle>
       
    
      <div class="hdg-label-popup">
   
  </div> 
      </div>
      
      </CardHeader>
      <CardImg alt="Image Unavailable" src={props.image} />
       
      <CardBody className="p-3">
        <CardTitle tag="h6"><Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>View Photos </h3>}><LocalSeeIcon  fontSize="large" onClick={openModal}></LocalSeeIcon></Tooltip>               <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Price History </h3>}><TrendingUpIcon  fontSize="large"   onClick={openPriceHistoryModal} /></Tooltip>                 <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Tax History </h3>}><AttachMoneyIcon   fontSize="large"  onClick={openTaxHistoryModal} /></Tooltip>              <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Nearby Homes and Map </h3>}><DirectionsIcon  fontSize="large"  onClick={openNearbyModal}/></Tooltip>              <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Send To Your Realtor </h3>}><MailOutlineIcon   fontSize="large"  onClick={openSharePropertyModal}/></Tooltip></CardTitle>

        <CardTitle tag="h6">{props.price + " Listed Price"}</CardTitle>
        <CardTitle tag="h6"><hr></hr></CardTitle>
        <CardTitle tag="h6"  className={checkPositive(props.priceIncreasePercentage) ? 'lowpercent' : 'highpercent' }>{buildPercentageString(props.priceIncreasePercentage)}</CardTitle>
        <CardText>{props.nearbyHomeAvg + " Nearby Home Avg"}</CardText>
        <CardTitle tag="h6"><hr></hr></CardTitle>
        <CardTitle tag="h6"  className={checkPositive(props.similarpriceincreasepercentage) ? 'lowpercent' : 'highpercent' }>{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</CardTitle>
        <CardText> <span className="font-link">{props.similarHomeAvg + " Similar Home Avg"}</span></CardText>
        <CardTitle tag="h6"><hr></hr></CardTitle>
        <CardTitle tag="h6"> <span className="font-link">{"Estimated Rental Amount " + props.rentZestimate}</span></CardTitle>
        <CardText className="mt-3" >
      
        {props.grossrentalyield} <Tooltip title={<span className="font-link-GRY-Desc">{yieldTooltip}</span>}><IconButton><HelpOutlineIcon />    </IconButton> 
        </Tooltip>
      </CardText> 
      <CardTitle tag="h6"><hr></hr></CardTitle>
      
      
        <CardTitle tag="h6"><span className="font-link">{"Total Square Feet: " + props.TotalSqrFt}</span></CardTitle>
        <CardTitle tag="h6"><span className="font-link">{"Price Per Square Feet " + props.PricePerSqrFt}</span></CardTitle>
        <CardTitle tag="h6"><span className="font-link">{"School Rating Avg(1-10) : " + props.SchoolRating}</span></CardTitle>
        <CardTitle tag="h6"><span className="font-link">{" MLS #: " +  props.mls }</span></CardTitle>
        <CardText><br></br></CardText>
        <CardSubtitle>{props.address}</CardSubtitle>
       
        <CardText className="mt-3">{props.text}</CardText>      
        
        <CardText className="mt-3">{props.description}</CardText>
        <CardText className="mt-4"><a target="_blank" href={"https://www.zillow.com/homes/for_sale/" + props.zpid.trim() + "_zpid/"} target="_blank">View Full Listing</a></CardText>
         {/* 
         
          <CardText className="mt-3"><p>{props.description} <Link to={'/details?zpid=' + props.zpid.trim() + '&loadid='+ props.loadID} >..View More</Link></p></CardText>
       
       
          <Button color={props.color} onClick={openModal}>View Photos</Button>
        <Button color={props.color} onClick={openPriceHistoryModal}>Price History</Button>
        <Button color={props.color} onClick={openTaxHistoryModal}>Tax History</Button>
        <Button color={props.color} onClick={openModal}>Nearby Homes</Button>
        <Button color={props.color}  onClick={openModal}>Send To Realtor</Button> */}
        {/* <Button color={props.color}  onClick= {() => { toggleBookMark(props.zpid); }}>Bookmark This Property</Button> */}

      {showModal ? <ImageModal setShowModal={setShowModal} gems={props}/> : null}
      {showPriceHistoryModal ? <PriceHistoryModal setShowPriceHistoryModal={setShowPriceHistoryModal} gems={props}/> : null}
      {showTaxHistoryModal ? <TaxHistoryModal setShowTaxHistoryModal={setShowTaxHistoryModal} gems={props}/> : null}
      {showNearbyModal ? <NearbyModal setShowNearbyModal={setShowNearbyModal} gems={props}/> : null}
      {showSharePropertyModal ? <SharePropertyModal setShowSharePropertyModal={setShowSharePropertyModal} gems={props}/> : null}
     
      </CardBody>
    </Card>
  );
};

export default PropertyCard;
