/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import "./carouselstyles.css";
export default ({ gems, background="white", className="video" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: background,
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
    
    <Carousel fade>
      {gems.imageList.map((gem, index) => (
          
          <Carousel.Item>
          <img
            className="d-block w-100"
            src={gem.url}
            alt="First slide"
          />
          <Carousel.Caption>
            {/* <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>

      ))}
      </Carousel>

    </div>
  );
};
