  export const API_URL= 'https://propertygems.azurewebsites.net' // Production
  export const FE_URL= 'https://investiocity.com' // Development
  export const LoginSignUpRedirect= 'http://investiocity.com' // Production

  //export const API_URL= 'https://localhost:49155' // Development
  //export const FE_URL= 'http://localhost:3000' // Development
  // export const LoginSignUpRedirect= 'http://localhost:49155/#/starter' // Development




