//import { Col, Row } from "reactstrap";
import React, { useState, useEffect, useCallback } from 'react';
 
import PropertyCard from "./PropertyCard";
import { Dropdown } from 'semantic-ui-react'
import { Card } from 'semantic-ui-react' 
import { Button } from 'semantic-ui-react'
 
//import PaginationBar from '../components/Pagination'
import { Pagination } from 'semantic-ui-react'
import {API_URL} from '../Constants'
//import ReactGA from 'react-ga';
import { Dimmer, Loader } from "semantic-ui-react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DropdownBoot from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
//import adImage from '../assets/images/Ads/JEHead.jpg';

//ReactGA.initialize('G-JH0W2RMXLE');

function currencyFormat(num) {
  if(num)
  {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }else 
  {
    return 'Not Available'
  }
  
}
function checkZero(num){
  if(num>0) 
  {
    const val = "Gross Rental Yield " + num +  "%";
    return val;
  }else 
  {
    return "Gross Rental Yield Unavailable ";
  }
}


export const Starter = () => {
  // const { token, setToken } = useToken(); 
  // if(!token) {
  //   return <Login setToken={setToken} />
  // }
  function refreshData()
  {
    setActive(true);
    let currentEmail = getCookie("email");
    
    //const urltosendRefresh = 'https://localhost:49153/Display/GetInitialLoad?offset=' + offSet + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType.value + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '';
    const urltosendRefresh = API_URL + '/Display/GetInitialLoad?offset=0&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '&email=' + currentEmail +  '';
    
    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';
  
    fetch(urltosendRefresh)
      .then((response) => response.json())
      .then((json) => {setData(json.model); setActive(false); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount))})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

     // setTotalPages(calculateTotalPages(data));
  }
  
  function refreshDataPaging(offsetvalue)
  {
    setActive(true);
   // let currentEmail = getCookie("email");
    let currentEmail = "pelbert@gmail.com";
    
    //const urltosendRefresh = 'https://localhost:49153/Display/GetInitialLoad?offset=' + offSet + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType.value + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '';
    const urltosendRefresh = API_URL + '/Display/GetInitialLoad?offset=' + offsetvalue + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '&email=' + currentEmail +  '';
    
    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';
  
    fetch(urltosendRefresh)
      .then((response) => response.json())
      .then((json) => {setData(json.model); setActive(false); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount))})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

     // setTotalPages(calculateTotalPages(data));
  }
  
function  calculateTotalPages(totalcount)  {
  return Math.ceil(totalcount/itemsPerPage);
}
  
  // function changePage(pageNum)
  // {
  //     setCurrentPageNum = pageNum;
  //     setOffSet = pageNum * 4; 
  // }
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
  const [orderBy, setOrderBy] = useState("ourRating");
  const [city,setCity]= useState("Houston");
  const [min,setMin]= useState(50000);
  const [max,setMax]= useState(950000);
  const [offSet,setOffSet]= useState(0); 
  const [isLoading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [totalPages, setTotalPages] = useState();
  const [data, setData] = useState([]);
  const [gemCount, setGemCount] = useState();
  const [email, setEmail]= useState();  
  const [cardRowCount, setCardRowCount]= useState(0);  
  const [active, setActive] = useState(true);
  const [weeksCount,setWeeksCount] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const parms = {offsetval:offSet, itemsperpageval:itemsPerPage, propertytypeval:propertyType, minpriceval:min, maxpriceval:max, orderby:orderBy, city:city};
  //const [open, setOpen] = useState(Array.from(data.model, () => false));
  const [state, setState] = useState({
    toggle: [],
    index: ""
  });
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  // Similar to componentDidMount and componentDidUpdate:
  //https://localhost:49153/
 // const encodedurl = 'https://localhost:49153/Display/GetInitialLoad?offset=${encodeURIComponent(parms.offsetval)}&itemsperpage=${encodeURIComponent(parms.itemsperpageval)}&propertytype=${encodeURIComponent(parms.propertytypeval)}&minprice=${encodeURIComponent(parms.minpriceval)}&maxprice=${encodeURIComponent(parms.maxpriceval)}'
 // const encodedurl = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=${encodeURIComponent(parms.offsetval)}&itemsperpage=${encodeURIComponent(parms.itemsperpageval)}&propertytype=${encodeURIComponent(parms.propertytypeval)}&minprice=${encodeURIComponent(parms.minpriceval)}&maxprice=${encodeURIComponent(parms.maxpriceval)}'
  useEffect(() => {
    //ReactGA.pageview(window.location.pathname);
   // ReactGA.pageview(window.location.pathname + window.location.search);
    //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
    //let currentEmail = getCookie("email");
    let currentEmail = "pelbert@gmail.com";
    const urltosend = API_URL + '/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval)  + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +'&email=' + encodeURIComponent(currentEmail) +  '';
    const countURL = API_URL + '/PropertyInfo/getWeeksPropertyCount';

    fetch(urltosend)
      .then((response) => response.json())
      .then((json) => {setData(json.model); setActive(false); setGemCount(json.gemCount[0].gemCount); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount))})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

      fetch(countURL)
      .then((response) => response.json())
      .then((json) => {setWeeksCount(json)})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
    

      //setTotalPages(calculateTotalPages(data));
  }, []);

const propertyTypesListData = [
  { key: 'Houses', text: 'Single Family', value: 'SINGLE_FAMILY' },
  { key: 'MultiFamily', text: 'Multi-Family', value: 'MULTI_FAMILY' },
  { key: 'MultiFamily', text: 'Townhomes', value: 'TOWNHOUSE' },
  { key: 'MultiFamily', text: 'Condos', value: 'CONDO' },

]
const orderByListData = [
  { key: 1, text: 'Investment Rating', value: 'ourRating' },
  { key: 2, text: 'Price Descending', value: 'Price' },
  { key: 3, text: 'Price Ascending', value: 'priceaccending' },
]
const CityData=[
  { key: 1, text: 'Houston', value: "Houston" },
  { key: 2, text: 'Austin', value: "Austin" }
]
function calculateOffset()
{
   return (itemsPerPage*currentPageNum) + 1 
}
const handleChangePropertyType = (e,dropdowninfo) => 
{
  setPropertyType(dropdowninfo.value);
}
const handleChangeCityData = (e,dropdowninfo) => 
{
  setCity(dropdowninfo.value);
} 
const handleChangeMin = (e,dropdowninfo) => 
{
  setMin(dropdowninfo.value);
} 
const handleChangeMax = (e,dropdowninfo) => 
{
  setMax(dropdowninfo.value);
} 
const handleChangeOrderBy = (e,dropdowninfo) => 
{
  setOrderBy(dropdowninfo.value);
}  




const onChange = (e, pageInfo) => {
  setCurrentPageNum(pageInfo.activePage);
  const offsetVal =  (pageInfo.activePage - 1) * (itemsPerPage + 1)
  setOffSet((pageInfo.activePage - 1) * (itemsPerPage + 1));
  refreshDataPaging(offsetVal);
  window.scrollTo(0, 0);
};
const MinValueListData= [
  { key: 1, text: '$50,000', value: 50000 },
  { key: 2, text: '$100,000', value: 100000},
  { key: 3, text: '$150,000', value: 150000 },
  { key: 4, text: '$200,000', value: 200000 },
  { key: 5, text: '$250,000', value: 250000 },
  { key: 6, text: '$300,000', value: 300000 },
  { key: 7, text: '$350,000', value: 350000 },
  { key: 8, text: '$400,000', value: 400000 },
  { key: 9, text: '$450,000', value: 450000 },
  { key: 10, text: '$500,000', value: 500000 },
  { key: 11, text: '$550,000', value: 550000 },
  { key: 12, text: '$600,000', value: 600000 },
  { key: 13, text: '$650,000', value: 650000 },
  { key: 14, text: '$700,000', value: 700000 },
  { key: 15, text: '$750,000', value: 750000 },
  { key: 16, text: '$800,000', value: 800000 },
  { key: 17, text: '$850,000', value: 850000 },
  { key: 18, text: '$900,000', value: 900000 },
  { key: 19, text: '$950,000', value: 950000 },
  { key: 20, text: '$1,000,000', value: 1000000 },

]
const MaxValueListData= [
  { key: 1, text: '$50,000', value: 50000 },
  { key: 2, text: '$100,000', value: 100000},
  { key: 3, text: '$150,000', value: 150000 },
  { key: 4, text: '$200,000', value: 200000 },
  { key: 5, text: '$250,000', value: 250000 },
  { key: 6, text: '$300,000', value: 300000 },
  { key: 7, text: '$350,000', value: 350000 },
  { key: 8, text: '$400,000', value: 400000 },
  { key: 9, text: '$450,000', value: 450000 },
  { key: 10, text: '$500,000', value: 500000 },
  { key: 11, text: '$550,000', value: 550000 },
  { key: 12, text: '$600,000', value: 600000 },
  { key: 13, text: '$650,000', value: 650000 },
  { key: 14, text: '$700,000', value: 700000 },
  { key: 15, text: '$750,000', value: 750000 },
  { key: 16, text: '$800,000', value: 800000 },
  { key: 17, text: '$850,000', value: 850000 },
  { key: 18, text: '$900,000', value: 900000 },
  { key: 19, text: '$950,000', value: 950000 },
  { key: 20, text: '> 1,000,000', value: 100000000 },

]
const handleChange = () => {
  setChecked(!checked);
};

//const handleChangePropertyType = (e, { value }) => setPropertyType({ value })
// const handleChangeCityData = (e, { value }) => setCity({ value.value })
// const handleChangeMin = (e, { value }) => setMin({ value })
// const handleChangeMax = (e, { value }) => setMax({ value })
// const handleChangeOrderBy = (e, { value }) => setOrderBy({ value })
//const handlePaginationChange = (e, {value}) => setCurrentPageNum({value})
const styleObjLeft = {
  fontSize: 25,
  color: "#4a54f1",
  textAlign: "left",
 // paddingTop: "100px",
}
const styleObjRight = {
  fontSize: 25,
  color: "#4a54f1",
  textAlign: "right",
  //paddingTop: "100px",
}
const mystyle = {
  color: "white",
  // backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};
  return (

  <div  style={{ 
    
    flexWrap: 'wrap', 
    }}>
           <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Finding Top Investments!</Loader>
    </Dimmer>
    </div> 
    
    {/* <Container style={{["justify-content"]: "flex-start" , backgroundColor: 'green'}} >
       <Row >
        <Col md="auto" > <img  height='155' src={adImage}></img></Col>
        <Col  md="auto" >
          
          <Container>
          <Row >
          
          <Col><h1 style={mystyle}>Your Houston Realtor!</h1> </Col>
        </Row>
        <Row >
          
          <Col><h2 style={mystyle}>Jacob Elbert</h2> </Col>
        </Row>
        <Row>
          
          <Col><h3 style={mystyle}>(832)576-6456 </h3></Col>
        </Row>

        
      </Container>
 
    
    
    </Col>
        <Col>
          
                <Container>
 
              <Row>
                
                <Col><h3 style={mystyle}>Specializing in Houston Single Family, Multi-Family Investments and Rentals! </h3></Col>
              </Row>
              <Row>
                
                <Col  ><h3 style={mystyle}>Call today with any questions about investing, buying or renting in Houston! </h3></Col>
              </Row>

              
            </Container>
       
          
          
          </Col>
      </Row>
      
    </Container>
         
       */}
 
 
      {/***Top Cards***/}
 		 {/* order by	CASE WHEN @orderby='ourRating' THEN ourRating  END Desc,
					CASE WHEN @orderby='price' THEN price END DESC */}
              <Container>
       <Row >
        <Col sm={12} ><span className="font-link-bigger"> {weeksCount.weeklyTotalScanned}</span><span className="font-link-med"> : The number total properties scanned by our algorithms in the last week alone to find the top investment properties!!</span></Col>
 </Row>
      <Row>
        <Col sm={12}><span className="font-link-bigger">  {weeksCount.weeklyTotalRated}</span><span className="font-link-med">   : The number of properties we identified as potential hot investments in the last week!</span></Col>
        {/* <Col sm={4} align='right'><span className="font-link-ratings-info "><Link to="/rankings" className="nav-link">How Our Rankings Work</Link></span></Col> */}
      </Row>
     
    </Container>
                   <Card.Group  box-shadow='none'   style={{ ["padding-bottom"]: 20 }} >
    <Card flex='1' box-shadow='none' fluid color='#70a4db' header='Option 1' >
    <Card.Content>
       
     

        <Card.Description  style={{ padding: 5 }} >
        <Dropdown selection={propertyType} onChange={handleChangePropertyType} placeholder={propertyType}    options={propertyTypesListData} /><Dropdown  onChange={handleChangeCityData}    placeholder={city}   options={CityData} selection={city} /><Dropdown  onChange={handleChangeMin}  placeholder={min}  options={MinValueListData} selection={min} /><Dropdown  onChange={handleChangeMax}  placeholder={max}   options={MaxValueListData} selection={max} /><Dropdown selection={orderBy} placeholder={orderBy} onChange={handleChangeOrderBy}  options={orderByListData}  />
        <Button primary  onClick={refreshData}>Filter Investments</Button>
         
        </Card.Description>
         
      </Card.Content>
      </Card>
 
  </Card.Group>
  
  <Pagination activePage={currentPageNum} onPageChange={onChange} totalPages={totalPages}   />
      {/* <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        Only Show New Rankings
      </label> */}
      
 <br>
 </br>
 <p>

 </p>
      <Row   class="container-fluid content-row">
 
        {data.map((gem, index) => (

          <Col key={index}>
            <PropertyCard 
              id = {index}
              image={gem.imgSrc}
              price={currencyFormat(gem.price)} 
              description={gem.shortdesc}
              address={gem.address}
              priceIncreasePercentage = {gem.priceIncreasePercentage} 
              grossrentalyield={checkZero(gem.grossRentalYield) }
              grossrentalyieldClean={ gem.grossRentalYield }
              color="primary"
              zpid = {gem.zpid}
              nearbyHomeAvg={currencyFormat(gem.nearbyHomeAvg)}
              imageList = {gem.imageList}
              ourRating  ={gem.ourRating}
              communityRating = {gem.communityRating}
              rentZestimate = {currencyFormat(gem.rentZestimate)} 
              similarHomeAvg = {currencyFormat(gem.similarHomeAvg)}
              loadID = {gem.loadId}
              similarpriceincreasepercentage= {gem.similarpriceincreasepercentage}
              isBookMarked={gem.isBookMarked}
              latitude={gem.latitude}
              longitude={gem.longitude}
              mls = {gem.mls}
              IsNew={gem.isNew}
              TotalSqrFt = {gem.totalSqrFt}
              PricePerSqrFt = {gem.pricePerSqrFt}
              SchoolRating = {gem.schoolRatingAvg}
              homeStatus = {gem.home_status}
              priceAction = {gem.priceAction}
              InvestmentDecisionReason= {gem.investmentDecisionReason}

            />
          </Col>
        ))} 

      </Row>
      <Pagination activePage={currentPageNum} onPageChange={onChange} totalPages={totalPages}   />
    </div>
  );
};

export default Starter;
