
import React, { useState, useEffect, useCallback } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Button from '@mui/material/Button';
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Header from "components/headers/light.js";
import {API_URL} from '../Constants'
import DialogActions from '@mui/material/DialogActions';
import StarRatings from 'react-star-ratings'; 
import PhotoNotFound from "../images/PhotoUnavailable.png";
import FiberNewTwoToneIcon from '@material-ui/icons/FiberNewOutlined';
import IconButton from "@material-ui/core/IconButton";
import Tooltip  from "@material-ui/core/Tooltip";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import LocalSeeIcon from '@material-ui/icons/LocalSee';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsIcon from '@material-ui/icons/Directions';
import MailOutlineIcon from '@material-ui/icons/MailOutline'; 
import VisibilityIcon from  '@material-ui/icons/Visibility';
//import DirectionsIcon from '@material-ui/icons/Directions';
// import { ImageModal } from "../legacy/imagemodal"
import { PriceHistoryModal } from "../legacy/PriceHistoryModal";
import { TaxHistoryModal } from "../legacy/TaxHistoryModal";
import { SharePropertyModal } from "../legacy/SharePropertyModal";
import Share from "../legacy/Share.component"
import NearbyModal  from "../legacy/NearbyModal";
import ReactModalAdapter from "../helpers/ReactModalAdapter.js"; 
import ResponsiveHistoryEmbed from "../helpers/ResponsiveHistoryEmbed.js";
import ResponsiveNearbyEmbed from "../helpers/ResponsiveNearbyEmbed.js";
import ResponsiveTaxEmbed from "../helpers/ResponsiveTaxEmbed.js";
import ResponsiveImageEmbed from "../helpers/ResponsiveImageEmbed.js"; 
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import CompsModal from '../legacy/CompsModal';
import {Dialog,DialogTitle,DialogContent} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/4`;
const CardColumn = tw(Column)`w-full pb-16  md:w-1/2 xl:w-1/4 mt-16 xl:mt-0`;
const CardStuff=tw.div``;
const HeadingInfoContainer = tw.div` text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
// const PrimaryLink = styled(PrimaryLinkBase)`
//   ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
//   svg {
//     ${tw`ml-2 w-5 h-5`}
//   }
// `;
// const WatchVideoButton = styled.button`
//   ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
//   .playIcon {
//     ${tw`stroke-1 w-12 h-12`}
//   }
//   .playText {
//     ${tw`ml-2 font-medium`}
//   }
// `;
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto  sm:m-16    max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-11/12 `}
  }
`;

const Card = tw.div`border-solid flex flex-1 flex-col justify-between h-full border-2 mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
//const CardType = tw.div`  font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold  text-lg`;
//const CardPriceAmountgreen = tw.span`font-bold  text-lg text-green-600`;
const CardTitle = tw.h5`text-xl mt-4 font-bold  `;

const CardMeta = styled.div`
  ${tw`h-64   flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8 align-bottom`;


 
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
 

 const PropCards = (props) => {
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
    const [orderBy, setOrderBy] = useState("ourRating");
    const [city,setCity]= useState("Houston");
    const [min,setMin]= useState(50000);
    const [max,setMax]= useState(950000);
    const [offSet,setOffSet]= useState(0); 
    const [isLoading, setLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalPages, setTotalPages] = useState();
    const [data, setData] = useState([]);
    const [gemCount, setGemCount] = useState();
    const [email, setEmail]= useState();  
    const [cardRowCount, setCardRowCount]= useState(0);  
    const [active, setActive] = useState(true);
    const [weeksCount,setWeeksCount] = useState([]);
    const [checked, setChecked] = React.useState(false);
 
 
    const [ImageModalIsOpen, setImageModalIsOpen] = useState(false);
    const [PriceHistoryModalIsOpen, setPriceHistoryModalIsOpen] = useState(false);
    const [CompsModalIsOpen, setCompsModalIsOpen] = useState(false);
    const [TaxHistoryModalIsOpen, setTaxHistoryModalIsOpen] = useState(false);
    const [NearbyModalIsOpen, setNearbyModalIsOpen] = useState(false);
    const [ShareModalIsOpen, setShareModalIsOpen] = useState(false);
    const [CityMapURL, setCityMapURL] = useState("/CityMapZpid?lat=" + props.latitude + "&longitude=" + props.longitude+ "&zpid=" + props.zpid + "&city=" + props.city )
    const toggleImageModal = () => setImageModalIsOpen(!ImageModalIsOpen);
    const togglePriceModal = () => setPriceHistoryModalIsOpen(!PriceHistoryModalIsOpen);
    const toggleCompsModal = () => setCompsModalIsOpen(!CompsModalIsOpen);
    const toggleTaxModal = () => setTaxHistoryModalIsOpen(!TaxHistoryModalIsOpen);
    const toggleNearbyModal = () => setNearbyModalIsOpen(!NearbyModalIsOpen);
    const toggleEmailModal = () => setShareModalIsOpen(!ShareModalIsOpen);

    const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const openPriceHistoryModal = () => {
    setShowPriceHistoryModal(true);
  };
  const [showTaxHistoryModal, setShowTaxHistoryModal] = useState(false);
  const openTaxHistoryModal = () => {
    setShowTaxHistoryModal(true);
  };
  const [showNearbyModal, setShowNearbyModal] = useState(false);
  const openNearbyModal = () => {
    setShowNearbyModal(true);
  };
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const openSharePropertyModal = () => {
    setShowSharePropertyModal(true);
  };
  const closeSharePropertyModal = () => {
    setShowSharePropertyModal(false);
  };
  const[isChecked,setIsChecked] = useState(data.isBookMarked);
const[watchVideoButtonText, setWatchVideoButtonText] =  useState("Watch Video"); 
const[watchVideoYoutubeUrl, setWatchVideoYoutubeUrl] =  useState("https://www.youtube.com/embed/_GuOjXYl5ew"); 
 
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;
  function toggleBookMark (zpid,props, index)  {
    //  data[index].isBookMarked = "Y";
    
   
    let email = getCookie("email");
    
    const urltosendRefresh = API_URL + '/BookMark/togglebookmark?email=' + encodeURIComponent(email) + '&zpid=' + encodeURIComponent( zpid)  + '';
    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      
  };
    fetch(urltosendRefresh,requestOptions) 
      .catch((error) => console.error(error)) 
      if (isChecked=='Y')
      {
        setIsChecked('N');
      }else 
      {
        setIsChecked('Y');
      }
      
  };
  


    function buildPercentageString(num) {
  
        if (num<0){
          return num + '% below nearby home average. '
        }else if (num>0) 
        {
          return num + '% above nearby home average. '
        } 
      }
      function buildSimilarPercentageString(num) {
        
        if (num<0){
          return num + '% below similar property average. '
        }else if (num>0) 
        {
          return num + '% above similar property average. '
        } 
      }
 
      function checkZero(num){
        if(num>0) 
        {
          const val = "Gross Rental Yield " + num +  "%";
          return val;
        }else 
        {
          return "Gross Rental Yield Unavailable ";
        }
      }
      function checkPositive(num) {
      
        if (num<0){
          return false
        }else if (num>0) 
        {
          return true
        } 
      }
      function checkIsBookMarked(val)
      {
        if (val=='Y'){
          return true
        }else {
          return false
        }
      }
   
      function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      };

    return (
    
 

                <CardColumn key={props.id}>
                  <Card   >
                  <CardText>
                        <CardTitle> 
                      <p style={{fontSize: "13px"}}>  Our Potential Investment Rating ({props.ourRating} out of 10) </p>
                        </CardTitle> 
                        <CardTitle> 
                            <StarRatings  rating={props.ourRating} starRatedColor="#f5cb42" numberOfStars={10} name='rating' starDimension='17px'  starSpacing="3px" /><p style={{fontSize: "13px"}}> {     props.ourRating}/10</p>
                        </CardTitle>
                  </CardText> 
                    <CardImage imageSrc={props.image} />
                    <p><p     style={{color:"red", align:"center",fontSize: "15px", display: props.isNew===false ? 'none' : 'inline'}}>JUST LISTED</p> <p style={{color:"red", align:"center",fontSize: "15px", display: (props.priceAction===null||props.priceAction===undefined|| props.priceAction==='')? 'none' : 'block'}}>{ props.priceAction}</p><p     style={{color:"red", align:"center",fontSize: "15px", display: props.priceAction==="REDUCED" ? 'none' : 'inline'}}>REDUCED</p></p>
                   
                    <p style={{fontSize: "16px",color:'black' }} >{  props.address}</p>
                    <hr>
                    </hr>
                     {/* <CardHeader> 
                     <CardType><p style={{fontSize: "16px",color:'black' }} >{  props.address}</p><Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>View Photos For This Property</h3>}><LocalSeeIcon    fontSize="medium" onClick={toggleImageModal}></LocalSeeIcon></Tooltip>               <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>View Available Price History </h3>}><TrendingUpIcon  fontSize="medium"   onClick={togglePriceModal} /></Tooltip>                 <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>View Available Tax History </h3>}><AttachMoneyIcon   fontSize="medium"  onClick={toggleTaxModal} /></Tooltip>              <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>View Nearby Home Pictures and Map </h3>}><DirectionsIcon  fontSize="medium"  onClick={toggleNearbyModal}/></Tooltip>              <Tooltip  title={<h3 style={{ fontSize: "18px", color:"white" }}>Send To Your Realtor or a Friend </h3>}><MailOutlineIcon   fontSize="medium"  onClick={toggleEmailModal}/></Tooltip></CardType>
                   </CardHeader> */}
                    <CardHeader> 
                   <Box spacing={2} sx={{ '& button': { m: 1 } }}>
                    <table style={{spacing: "8"}}>
                      <tr >
                        <td><Button onClick={toggleImageModal} variant="contained" startIcon={<LocalSeeIcon />}>
                           Photos   
                        </Button>
                        </td>
                        <td><Button onClick={toggleNearbyModal} variant="contained" startIcon={<DirectionsIcon />}>
                          Neighbors
                        </Button>
                        </td>
                        
                      </tr>
                      <tr>
                        <td><Button   onClick={togglePriceModal} variant="contained" startIcon={<TrendingUpIcon />}>
                        Price Hist.
                        </Button>
                        </td> 
                        <td><Button   onClick={toggleTaxModal} variant="contained" startIcon={<AttachMoneyIcon />}>
                          Tax History
                        </Button>
                        </td>
                        </tr>
                      <tr>
                        <td><Button onClick={toggleCompsModal} variant="contained" startIcon={<CompareArrowsIcon />}>
                          Comparisons
                        </Button>
                        </td>
                        <td><a href={props.mapUrl} target='_blank'> <Button variant="contained" startIcon={<VisibilityIcon />}>
                          Rating Map
                        </Button></a>
                        </td>


               
                      </tr>
                    </table>
                    </Box >
                    <br></br>
                    </CardHeader >
                   {/* <CardAction onClick={toggleCompsModal} style={{ bottom:0}} >View Comps</CardAction>
                   <a href={CityMapURL} target='_blank'> <CardAction  style={{ bottom:0}} >View On Investment Map</CardAction></a>
                   */}
                  <CardPrice>
                       <CardPriceAmount>{props.price + " Listed Price"}</CardPriceAmount>  
                       <div     style={{fontSize: "15px"}}> 
                      Days On Market: {props.timeOnZillow}
                       </div>
                      <div     style={{align:"center",fontSize: "15px"}}> {"Property Status:  " + props.home_status}</div>
                    
                       </CardPrice>
                     <CardText>
                      <div   >  
                      <Tooltip  title={<p style={{ fontSize: "15px", color:"White" }}>{props.investmentDecisionReason} </p>}>
                      <p style={{fontSize: "15px"}}>Why is it ranked this way?<IconButton><HelpOutlineIcon />    </IconButton></p>
                      </Tooltip> 
                      </div>
                      <div     style={{fontSize: "15px"}}> 
                      {checkIsBookMarked(isChecked)?'Bookmarked!':'Bookmark This Property'} </div>
                      <div     style={{align:"center",fontSize: "15px"}}> {"Property Status:  " + props.home_status}</div>
                      </CardText>
                    <CardText  >
                        <CardTitle> 
                          <div style={{fontSize: "15px",color:checkPositive(props.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(props.priceIncreasePercentage)}</div>
                          <div style={{fontSize: "15px",color:'black' }} >{props.nearbyHomeAvg + " Nearby Home Avg"}</div>
                        </CardTitle>
                        <CardTitle> 
                         <hr style={{color:'black',borderWidth:3}}></hr>
                        </CardTitle>
                        <CardTitle> 
                          <div style={{fontSize: "15px",color:checkPositive(props.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(props.similarpriceincreasepercentage)}</div>
                          <div style={{fontSize: "15px",color:'black' }} >{props.similarHomeAvg + " Similar Home Avg"}</div>
                        </CardTitle>
                        <CardTitle> 
                         <hr style={{color:'black',borderWidth:3}}></hr>
                        </CardTitle>
                        <CardTitle> 
                        <div style={{fontSize: "15px",color:'black' }} >{"Estimated Rental Amount " + props.rentZestimate}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{checkZero(props.grossRentalYield)} </div>
                      
                        </CardTitle>
                        <CardTitle> 
                         <hr style={{color:'black',borderWidth:3}}></hr>
                        </CardTitle>
                        <CardTitle> 
                        <div style={{fontSize: "15px",color:'black' }} >{"Total Square Feet: " + props.totalSqrFt}</div>

                        <div style={{fontSize: "15px",color:'black' }} >{"Price Per Square Feet " + props.pricePerSqrFt}</div>
                        <div style={{fontSize: "15px",color:'black' }} >{"School Rating Avg(1-10) : " + props.schoolRatingAvg} </div>
                        <div style={{fontSize: "15px",color:'black' }} >{" MLS #: " +  props.mls } </div>
                        <div style={{fontSize: "15px",color:'black' }} >{"Bedrooms: " + props.bedrooms}</div>
                        </CardTitle>
                        <CardTitle> 
                         <hr style={{color:'black',borderWidth:3}}></hr>
                        </CardTitle>
                        <CardTitle> 
                        <div style={{fontSize: "15px",color:'gray' }} >{  props.description}</div>
                         
                        </CardTitle>
                    </CardText>
                    <a target="_blank" href={"https://www.zillow.com/homes/for_sale/" + props.zpid.trim() + "_zpid/"} target="_blank"><CardAction  style={{ bottom:0}} >View Full Listing</CardAction></a>
                    <CardAction  onClick={toggleEmailModal} style={{ bottom:0}} >Email This Property To Yourself</CardAction>
                    {/* <CardAction  onClick={toggleEmailModal} style={{ bottom:0}} >Talk To A Realtor About This Property</CardAction> */}
                 
                  </Card>
    
                <CardText>
              
                  {showPriceHistoryModal ? <PriceHistoryModal setShowPriceHistoryModal={setShowPriceHistoryModal} gems={props}/> : null}
                  {showTaxHistoryModal ? <TaxHistoryModal setShowTaxHistoryModal={setShowTaxHistoryModal} gems={props}/> : null}
                  {showNearbyModal ? <NearbyModal setShowNearbyModal={setShowNearbyModal} gems={props}/> : null}
                  {showSharePropertyModal ? <SharePropertyModal setShowSharePropertyModal={setShowSharePropertyModal} gems={props}/> : null}
    
                </CardText>


                {/* Dialog for Notes */}
      <Dialog  fullWidth maxWidth="md" open={CompsModalIsOpen} onClose={toggleCompsModal}>
        <DialogTitle></DialogTitle>
        <DialogContent>
         
        <CompsModal setShowCompsModal='true' gems={props} />
       
           </DialogContent>
        <DialogActions>
          <Button onClick={toggleCompsModal}>Close</Button> 
        </DialogActions>
      </Dialog>

      {/* Dialog for ssharepropertymodal */}
      <Dialog  fullWidth maxWidth="md" open={ShareModalIsOpen} onClose={closeSharePropertyModal}>
        <DialogTitle></DialogTitle>
        <DialogContent>
         
        {/* <SharePropertyModal setShowSharePropertyModal='true' gems={props} />
        */}
         <Share  data={props} />
           </DialogContent>
        <DialogActions>
          <Button onClick={toggleEmailModal}>Close</Button> 
        </DialogActions>
      </Dialog>



          {/* <StyledModal
           
           className="mainHeroModal"
           isOpen={CompsModalIsOpen}
           onRequestClose={toggleCompsModal}
           shouldCloseOnOverlayClick={true}
         >
           <CloseModalButton onClick={toggleCompsModal}>
             <CloseIcon tw="w-6 h-6" />
           </CloseModalButton>
           <div style={{ width:'70%'}}>
             <CompsModal setShowCompsModal='true' gems={props} />
           </div>
         </StyledModal> */}
                <StyledModal
           
          className="mainHeroModal"
          isOpen={PriceHistoryModalIsOpen}
          onRequestClose={togglePriceModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={togglePriceModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div style={{ width:'70%'}}>
            <ResponsiveHistoryEmbed gems={props}   />
          </div>
        </StyledModal>
        <StyledModal
           
           className="mainHeroModal"
           isOpen={NearbyModalIsOpen}
           onRequestClose={toggleNearbyModal}
           shouldCloseOnOverlayClick={true}
         >      
         
           <CloseModalButton onClick={toggleNearbyModal}>
    <CloseIcon tw="w-6 h-6" />
           </CloseModalButton>
           <div style={{  overflow:'scroll',width:'70%'}}>
             <ResponsiveNearbyEmbed gems={props}   />
           </div>
         </StyledModal>
         <StyledModal
           
           className="mainHeroModal"
           isOpen={TaxHistoryModalIsOpen}
           onRequestClose={toggleTaxModal}
           shouldCloseOnOverlayClick={true}
         >      
         
           <CloseModalButton onClick={toggleTaxModal}>
    <CloseIcon tw="w-6 h-6" />
           </CloseModalButton>
           <div style={{  overflow:'scroll',width:'70%'}}>
             <ResponsiveTaxEmbed gems={props}   />
           </div>
         </StyledModal>
       
         <StyledModal
           
           className="mainHeroModal"
           isOpen={ImageModalIsOpen}
           onRequestClose={toggleImageModal}
           shouldCloseOnOverlayClick={true}
         >      
         
           <CloseModalButton onClick={toggleImageModal}>
    <CloseIcon tw="w-6 h-6" />
           </CloseModalButton>
           <div style={{  overflow:'scroll',width:'70%'}}>
             <ResponsiveImageEmbed gems={props}   />
           </div>
         </StyledModal>
                </CardColumn>
               
              )
         
}

export default PropCards;