
import React, { useState, useEffect, useCallback } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
 
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Header from "components/headers/light.js";
import {API_URL} from '../Constants'
import { Pagination } from 'semantic-ui-react' 
import { Dimmer, Loader } from "semantic-ui-react";
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import Footer from "components/footers/MiniCenteredFooter.js";
//import { ImageModal } from "../legacy/ModalTest"
import {  useSearchParams } from 'react-router-dom'
import { ImageModal } from "../legacy/reactmodaltest"
import "../legacy/Card2.css";
import { ReactComponent as ArrowRightIcon } from "../images/arrow-right-icon.svg";
import PropCards from './PropCards';
import SimpleSubscribeNewsletter from 'components/forms/SimpleSubscribeNewsletter';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
 const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`border-solid flex flex-1 flex-col justify-between h-full border-2 mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`  font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold  text-lg`;
const CardPriceAmountgreen = tw.span`font-bold  text-lg text-green-600`;
const CardTitle = tw.h5`text-xl mt-4 font-bold  `;

const CardMeta = styled.div`
  ${tw`h-64   flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8 align-bottom`;


function currencyFormat(num) {
    if(num)
    {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }else 
    {
      return 'Not Available'
    }
    
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
 
 
export default () => {

 
 
 
      function calculateOffset()
    {
       return (itemsPerPage*currentPageNum) + 1 
    }
    const handleChangePropertyType = (e,dropdowninfo) => 
    {
      setPropertyType(dropdowninfo.value);
    }
    const handleChangeCityData = (e,dropdowninfo) => 
    {
      setCity(dropdowninfo.value);
    } 
    const handleChangeMin = (e,dropdowninfo) => 
    {
      setMin(dropdowninfo.value);
    } 
    const handleChangeMax = (e,dropdowninfo) => 
    {
      setMax(dropdowninfo.value);
    } 
    const handleChangeOrderBy = (e,dropdowninfo) => 
    {
      setOrderBy(dropdowninfo.value);
    }  



    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
    const [orderBy, setOrderBy] = useState("ourRating");
    const [city,setCity]= useState("Houston");
    const [min,setMin]= useState(50000);
    const [max,setMax]= useState(950000);
    const [offSet,setOffSet]= useState(0); 
    const [isLoading, setLoading] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(24);
    const [totalPages, setTotalPages] = useState();
    const [data, setData] = useState([]);
    const [gemCount, setGemCount] = useState();
    const [email, setEmail]= useState();  
    const [cardRowCount, setCardRowCount]= useState(0);  
    const [active, setActive] = useState(true);
    const [weeksCount,setWeeksCount] = useState([]);
    const [checked, setChecked] = React.useState(false);
    // const [currentEmail, setCurrentEmail] = useState( getCookie("email"));
    const [currentEmail, setCurrentEmail] = useState( "pelbert@gmail.com");

    const [showModal, setShowModal] = useState(true);
  const openModal = () => {
    setShowModal(true);
  };
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const openPriceHistoryModal = () => {
    setShowPriceHistoryModal(true);
  };
  const [showTaxHistoryModal, setShowTaxHistoryModal] = useState(false);
  const openTaxHistoryModal = () => {
    setShowTaxHistoryModal(true);
  };
  const [showNearbyModal, setShowNearbyModal] = useState(false);
  const openNearbyModal = () => {
    setShowNearbyModal(true);
  };
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const openSharePropertyModal = () => {
    setShowSharePropertyModal(true);
  };

  const [searchParams, setSearchParams] =useSearchParams(); 
  // const q = searchParams.get('test')
   const [zpid, setZpid] = useState(searchParams.get('zpid'));
   const [eid, setEid] = useState(searchParams.get('eid'));

    const parms = {offsetval:offSet, itemsperpageval:itemsPerPage, propertytypeval:propertyType, minpriceval:min, maxpriceval:max, orderby:orderBy, city:city};
    const [loadID, setLoadID] = useState(searchParams.get('loadid'));
   
function  calculateTotalPages(totalcount)  {
    return Math.ceil(totalcount/itemsPerPage);
  }
  
    function currencyFormat(num) {
      if(num)
      {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }else 
      {
        return 'Not Available'
      }
      
    }
    function checkZero(num){
      if(num>0) 
      {
        const val = "Gross Rental Yield " + num +  "%";
        return val;
      }else 
      {
        return "Gross Rental Yield Unavailable ";
      }
    }
    function checkPositive(num) {
    
      if (num<0){
        return false
      }else if (num>0) 
      {
        return true
      } 
    }
    function createMapUrl(lat,lng,zpid,propertytype,city)
    {
      var url = "/CityMapZpid?lat=" + lat + "&longitude=" + lng + "&propertytype=" + propertytype + "&zpid=" + zpid.trim() + "&city=" + city 
      return url; 
  
  
  
    }
    function checkIsBookMarked(val)
    {
      if (val=='Y'){
        return true
      }else {
        return false
      }
    }
    function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    };
    
 
 

    useEffect(() => {
         
          //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
          let currentEmail = getCookie("email");
         
          
         const urltosend = API_URL + '/Display/GetDetails?zpid=' + zpid + '&loadid=' + loadID + '&eid=' + eid ;
      
          fetch(urltosend)
            .then((response) => response.json())
            .then((json) => {setData(json.model); setActive(false)})
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
      
            //setTotalPages(calculateTotalPages(data));
        }, []);
      
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1553194587-b010d08c6c56?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Beachfront",
      pricePerDay: "$99",
      title: "A Trip to the Bahamas and the Carribean Ocean",
      trendingText: "Trending",
      durationText: "7 Days Tour",
      locationText: "Africa"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1584200186925-87fa8f93be9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Cruise",
      pricePerDay: "$169",
      title: "Cruise to the Mariana Trench and the Phillipines",
      trendingText: "Trending",
      durationText: "15 Days Tour",
      locationText: "Australia"
    }
  ];
  const yieldTooltip ="Gross rental yield refers to the gross yield produced by a rental property. It's simply a function of the rent collected over the course of a year expressed as a percentage of the property's market value. A rental property with a market value of $100,000 that generates $12,000 in rental income each year would have a gross rental yield of 12%. Gross rental yield is not reflective of the actual profits (if any) an investment property generates. It is only based on annual rental income. It doesnt take expenses such as property taxes, management fees, and other operating expenses into account. This would be the net rental yield. In fact, it is certainly possible for a property to have a strong gross rental yield and still produce poor cash flow for its owner.For this reason, gross rental yield can be useful for a quick comparison between two investment properties or to narrow down a list of potential investments. ";

  return (
    
    <Container> 
          
         <Header />
         <div className='Loader'>
          <Dimmer
        active = {active}
        page={true}  size='massive' inverted> 
       <Loader inverted>Getting Property Details!</Loader>
    </Dimmer>
    </div> 
    <SimpleSubscribeNewsletter/>

      <Content>
     
        <ThreeColumn> 
        <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Understand The Rankings</HeadingTitle>
              <HeadingDescription>
                We only show properties our algorithms rank from 1-10. While we scan every listing in a city we only show and rank properties our algorithms determine to have high profit potential. 
              </HeadingDescription>
              <a href='/investments'>
              <PrimaryLink>
                SEE THE MAGIC <ArrowRightIcon />
              </PrimaryLink>
              </a>
            </HeadingInfoContainer>
          </HeadingColumn>
          {data.map((card, index) => (
         
              <PropCards 
                id = {index}
                image={card.imgSrc}
                price={currencyFormat(card.price)} 
                shortdesc={card.shortdesc}
                address={card.address}
                priceIncreasePercentage = {card.priceIncreasePercentage} 
                grossRentalYield={card.grossRentalYield }
                mapUrl={createMapUrl(card.latitude,card.longitude,card.zpid,card.propertytype,card.city)}               
                color="primary"
                zpid = {card.zpid}
                nearbyHomeAvg={currencyFormat(card.nearbyHomeAvg)}
                imageList = {card.imageList}
                ourRating  ={card.ourRating}
                communityRating = {card.communityRating}
                rentZestimate = {currencyFormat(card.rentZestimate)} 
                similarHomeAvg = {currencyFormat(card.similarHomeAvg)}
                loadID = {card.loadId}
                similarpriceincreasepercentage= {card.similarpriceincreasepercentage}
                isBookMarked={card.isBookMarked}
                latitude={card.latitude}
                longitude={card.longitude}
                mls = {card.mls}
                isNew={card.isNew}
                totalSqrFt = {card.totalSqrFt}
                pricePerSqrFt = {card.pricePerSqrFt}
                schoolRatingAvg = {card.schoolRatingAvg}
                home_status = {card.home_status}
                priceAction = {card.priceAction}
                investmentDecisionReason= {card.investmentDecisionReason}
  
              />
      
             
          ))}
           {/* <Pagination activePage={currentPageNum} onPageChange={onChange} totalPages={totalPages}   /> */}
        </ThreeColumn>
        {/* <ThreeColumn> 
        <a target="_blank" href='/'><CardAction  style={{ bottom:0}} >Browse All Ranked Investments</CardAction></a>
                 
      </ThreeColumn>  */}
      </Content>
      <Footer />
    </Container>
  );
};
