import React, { useState } from 'react'
import { TextArea } from 'semantic-ui-react';
import {API_URL} from '../Constants'
import { Dimmer, Loader } from "semantic-ui-react";
import "./styles.css"; 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';






function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
//passed in props : zpid , loadid , realtorname, realtoremail(last two from form, message(from form), useremail, username (from cookies?)  )
//sendEmailToRealtor(string zpid, Guid loadID,string realtorEmail, string realtorName, string message, string useremail, string username)

  
const Share = (props) => {

  async function emailRealtor(shareinfo) {
    setActive(true);
    //const postData = { email: email ,password:password ,firstname:firstName, lastname:lastName,newletter:newsletter};
  
      return fetch(API_URL + '/api/email/sendEmailToRealtor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(shareinfo)
      })
        .then(data =>{ data.json(); setShowResults(true); setActive(false)})
     }
  //  function emailRealtor(user) {
  //   //const postData = { email: email ,password:password ,firstname:firstName, lastname:lastName,newletter:newsletter};
  //      fetch('https://localhost:49153/api/email/sendEmailToRealtor', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(user)
  //     })
  //       .then(data => data.json())
  // } 

  
  const [username, setUserName] = useState("Unknown");
  const [useremail, setUserEmail] = useState();
    const [recipient, setRecipient] = useState("Unknown");
    const [recipientEmail, setRecipientEmail] = useState();
    const [message, setMessage] = useState("Check out this incredible investment I found on Investiocity!");
    const [emailValid, setEmailValid] = useState(true);
    const [lastName, setLastName] = useState();
    const [newsletter, setNewsletter] = useState(true);
    const [active, setActive] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [showAgree, setAgree] = useState(false);
    let zpid=props.data.zpid.trim();
    let loadid = props.data.loadID;
    //let useremail = getCookie("email");

    function emailValidation(){
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if(!useremail || regex.test(useremail) === false){
          setEmailValid(false);
          return false;
      }
      setEmailValid(true);
      return true;
    }
    const handleClick= async e => {
    
      if (showAgree==true)
      {
        setAgree(false);
      }else 
      {
        setAgree(true);
      }

    }
    const handleSubmit = async e => {
      e.preventDefault();
      if (showAgree===false)
      {
        setErrorMsg("You must agree to be added to our newsletter to send this property");
        return false; 
      }else 
      {
        setErrorMsg("");
      }
      if(!emailValidation()){
        setEmailValid(false);
      }else {
        const token=  await emailRealtor({
          zpid,
          loadid,
          recipientEmail,
          recipient,
          message,
          useremail,
          username
        });
    }
      


   
  

      //zpid            props
//loadid          props
//realtorEmail     from form
//realtorName      from form
//message         from form
//useremail      from cookie
//username        from cookie
      // if (typeof token.token !== 'undefined')
      // {
      //   setCookie("token", token.token,60);
      //   setCookie("email", token.email,60);
      //   setShowResults(false);
      //   window.location.replace("http://localhost:3000/#/starter");
      // }else {
      //   setShowResults(true);
      // } 
      }
 
    return (
      <div>
      <form  onSubmit={handleSubmit}>
     
        <div>
          
        <div className='Loader'>
           
        <Loader active = {active} className='workaround' size='large' inline='centered' />
    
    </div>

  
        <h3 style={{color:"Navy"}}> You are sharing the following property:  </h3>
        <label style={{color:"Black"}}> Address:  {props.data.address} </label><br/> 
        <label style={{color:"Black"}}> Price: {props.data.price} </label><br/> 
        <label style={{color:"Black"}}> Estimate Gross Rental Yield: {props.data.grossRentalYield}  </label>
        <br></br>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
      <Grid item xs={2}>
          <Item> <label style={{color:"Navy"}}>Your Name</label><TextField   required  id="outlined-required" label="Required" defaultValue=""  onChange={e => setUserName(e.target.value)} />
     
         </Item>
        </Grid>
      <Grid item xs={2}>
          <Item> <label style={{color:"Navy"}}>Your Email</label> <TextField   required id="outlined-basic" label="Required"  defaultValue=""  onChange={e => setUserEmail(e.target.value)} />
     
         </Item>
        </Grid>
        {/* <Grid item xs={2}>
          <Item> <label style={{color:"Navy"}}>Realtor Name</label> <TextField   required id="outlined-basic" label="Outlined" variant="outlined"  onChange={e => setRecipient(e.target.value)} />
     
         </Item>
        </Grid>
        <Grid item xs={2}>
        <Item> <label style={{color:"Navy"}}>Realtor Email</label> <TextField   required id="outlined-basic" label="Outlined" variant="outlined"  onChange={e => setRecipientEmail(e.target.value)} />
     
     </Item>
        </Grid> */}
       
       
      </Grid>
    </Box>
    {/* <Box>
    <Grid item xs={4}>
        <Item> <label style={{color:"Navy"}}>Message</label> <TextField id="outlined-basic" label="Outlined" variant="outlined"  onChange={e => setMessage(e.target.value)}  />
     
     </Item>
        </Grid>
    </Box> */}
    <br></br>
    <FormGroup>
      <FormControlLabel control={<Checkbox onChange={() =>handleClick()}  />} label=" * By sending this info to yourself you agree to be added to our FREE daily newsletter that sends top properties to your inbox every morning. " />
      <label style={{color:"red"}}> {errorMsg} </label>
        
    </FormGroup>
<br></br>
        <div className="mb-3">
        <button type="submit"  className="mybutton"  style={{height: '45px', width : '200px'}} >
            Send to Recipient
          </button>
        </div>
       
        </div>
      </form>
      </div>
    )
  
}
//zpid            props
//loadid          props
//realtorEmail     from form
//realtorName      from form
//message         from form
//useremail      from cookie
//username        from cookie

export default Share;