import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";

const Row = tw.div`flex flex-col lg:flex-row -mb-10`;
const Heading = tw(SectionHeading)`text-left lg:text-4xl xl:text-5xl`;

const PopularPostsContainer = tw.div`lg:w-2/3`;
const PostsContainer = tw.div`mt-12 flex flex-col sm:flex-row sm:justify-between lg:justify-start`;
const Post = tw(motion.a)`block sm:max-w-sm cursor-pointer mb-16 last:mb-0 sm:mb-0 sm:odd:mr-8 lg:mr-8 xl:mr-16`;
const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const Title = tw.h5`mt-6 text-xl font-bold transition duration-300 group-hover:text-primary-500`;
const Description = tw.p`mt-2 font-medium text-secondary-100 leading-loose text-sm`;
const AuthorInfo = tw.div`mt-6 flex items-center`;
const AuthorImage = tw.img`w-12 h-12 rounded-full`;
const AuthorNameAndProfession = tw.div`ml-4`;
const AuthorName = tw.h6`font-semibold text-lg`;
const AuthorProfile = tw.p`text-secondary-100 text-sm`;

const RecentPostsContainer = styled.div`
  ${tw`mt-24 lg:mt-0 lg:w-1/3`}
  ${PostsContainer} {
    ${tw`flex flex-wrap lg:flex-col`}
  }
  ${Post} {
    ${tw`flex justify-between mb-10 max-w-none w-full sm:w-1/2 lg:w-auto sm:odd:pr-12 lg:odd:pr-0 mr-0`}
  }
  ${Title} {
    ${tw`text-base xl:text-lg mt-0 mr-4 lg:max-w-xs`}
  }
  ${AuthorName} {
    ${tw`mt-3 text-sm text-secondary-100 font-normal leading-none`}
  }
  ${Image} {
    ${tw`h-20 w-20 flex-shrink-0`}
  }
`;
const PostTextContainer = tw.div``

export default () => {
  // This setting is for animating the post background image on hover
  const postBackgroundSizeAnimation = {
    rest: {
      backgroundSize: "100%"
    },
    hover: {
      backgroundSize: "110%"
    }
  };

  //Recommended: Only 2 Items
  const popularPosts = [
    {
      postImageSrc:
        "https://www.quickenloans.com/learnassets/QuickenLoans.com/Learning%20Center%20Images/Stock-Investment-Property-AdobeStock-320546348.jpeg",
      authorImageSrc:
        "https://www.rocketmortgage.com/resources-cmsassets/RocketCareers/AuthorPhotos/Victoria-headshot.jpg",
      title: "Buying an investment property: 3 signs you're ready and what you need to know",
      description:
        "We've created a crash course on everything you need to know before you get a loan for your first investment property and start making money.",
      authorName: "VICTORIA ARAJ",
      authorProfile: "Mortgage Expert",
      url: "https://www.rocketmortgage.com/learn/investment-property#:~:text=Investment%20Property%20Definition,or%20group%20of%20investors%20together."
    },
    {
      postImageSrc:
        "https://www.thanmerrill.com/wp-content/uploads/2018/11/investment-property-depreciation.jpg",
      authorImageSrc:
        "https://www.quickenloans.com/learnassets/Rocket%20Homes/AuthorImages/SidneyHeadshot.png",
      title: "Buying An Investment Property: The Complete Guide",
      description:
        "Let’s go over what an investment property is, some of the different types and how you can start making well-researched and lucrative investment decisions yourself.",
      authorName: "Sidney Richardson",
      authorProfile: "Real estate specialist and blogger",
      url: "https://www.quickenloans.com/learn/investment-property#nogo"
    }
  ];

  const recentPosts = [
    {
      postImageSrc:
        "https://www.greenresidential.com/wp-content/uploads/Finding-Good-Tenants-Is-One-Thing-Retaining-Them-Is-Another.jpg",
      title: "Finding good Tenants is one Thing-Retaining Them is Another",
      authorName: "Green residential",
      url: "https://www.greenresidential.com/finding-good-tenants-is-one-thing-retaining-them-is-another/"
    },
    {
      postImageSrc: "https://wp-tid.zillowstatic.com/7/refinancing-investment-property-b8ebd0-1024x598.jpg",
      title: "Investment and rental property mortgage rates: 2022 Guide",
      authorName: "Gina Freeman",
      url: "https://themortgagereports.com/27698/investment-property-mortgage-rates-how-much-more-will-you-pay"
    },
    {
      postImageSrc:
        "https://www.bankrate.com/2019/10/04112142/Guide-to-flipping-houses.jpg",
      title: "5 Mistakes That Can Make House Flipping a Flop",
      authorName: "James Mcwhinney",
      url: "https://www.investopedia.com/articles/mortgages-real-estate/08/house-flip.asp"
    },
    {
      postImageSrc:
        "https://i2.cdn.turner.com/money/dam/assets/180604110144-malibu-home-flip-kitchen-340xa.jpg",
      title: "How To Get A loan For Flipping Houses",
      authorName: "Scott Steinberg",
      url: "https://www.rocketmortgage.com/learn/loans-for-flipping-houses"
    },
    {
      postImageSrc:
        "https://wp-assets.stessa.com/wp-content/uploads/2020/11/17175628/pexels-cottonbro-5077049-e1633707554829.jpg",
      title: "6 Key Steps To Effectively Managing Your Rental Properties",
      authorName: "Jeff Rojde",
      url: "https://www.stessa.com/blog/managing-rental-properties/"
    },
  ]

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <PopularPostsContainer>
            <Heading>Popular Posts</Heading>
            <PostsContainer>
              {popularPosts.map((post, index) => (
                <Post key={index} target="_blank"   href={post.url} className="group" initial="rest" whileHover="hover" animate="rest">
                  <Image
                    transition={{ duration: 0.3 }}
                    variants={postBackgroundSizeAnimation}
                    $imageSrc={post.postImageSrc}
                  />
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <AuthorInfo>
                    <AuthorImage src={post.authorImageSrc} />
                    <AuthorNameAndProfession>
                      <AuthorName>{post.authorName}</AuthorName>
                      <AuthorProfile>{post.authorProfile}</AuthorProfile>
                    </AuthorNameAndProfession>
                  </AuthorInfo>
                </Post>
              ))}
            </PostsContainer>
          </PopularPostsContainer>
          <RecentPostsContainer>
            <Heading>Recent Posts</Heading>
            <PostsContainer>
              {recentPosts.map((post, index) => (
              <Post key={index} target="_blank" href={post.url} className="group">
                <PostTextContainer>
                  <Title>{post.title}</Title>
                  <AuthorName>{post.authorName}</AuthorName>
                </PostTextContainer>
                <Image $imageSrc={post.postImageSrc} />
              </Post>
              ))}
            </PostsContainer>
          </RecentPostsContainer>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
