import React,{ useEffect,useState }  from 'react';
import Text from 'react';
import GoogleMapReact from 'google-map-react';
import { Carousel } from 'react-bootstrap';
import Marker  from './Markers.tsx';
import { CropPortrait } from '@material-ui/icons';
import { color } from '@mui/system';
import { Dimmer, Loader } from "semantic-ui-react";
import {API_URL} from '../Constants'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Label } from 'semantic-ui-react'
import './ModalStyles.css';
import './modal.css';
import {
  CardHeader,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Button
} from "reactstrap";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
function currencyFormat(num) {
  if(num)
  {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }else 
  {
    return ' Not Available'
  }
  
}
const Nearby = (gems) => {
 
   const defaultProps = {
    height:30,
    center: {
      lat: gems.props.latitude,
      lng: gems.props.longitude
    },
    zoom: 18
  };
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  function setDefaultProps(gems) {
    const mapCenterLat = gems.props.latitude;
    const mapCenterLng = gems.props.longitude ;
   
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function checkPositive(num) {
    
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  useEffect(() => {
  
    
    //Need to pass this in 
    
  
    const encodedValue = encodeURIComponent(gems.zpid);
    fetch( API_URL + '/PropertyInfo/getNearbyInfo?parentzpid=' + gems.props.zpid + '&loadid=' + gems.props.loadID)
        .then((response) => response.json())
        .then((json) => {setData(json); setActive(false)})
        .catch((error) => console.error(error))
        .finally(() => setDefaultProps(gems));
    }, []);
    return (  
      // Important! Always set the container height explicitly
 
      <div style={{height: '250px', width: '100%'}}>
          <div className='Loader'>
           
           <Loader active = {active} className='workaround' size='large' inline='centered' />
       
       </div>
        <GoogleMapReact
          height={10}
          bootstrapURLKeys={{ key: 'AIzaSyCETi424NnsIH2Chp6XbmhGvHSRni6ok-w' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
         // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
        {data.map((gem2, index2) => (         
          <Marker
            lat={gem2.coordinate.latitude}
            lng={gem2.coordinate.longitude}
            color="red"
          />
          ))} 
          <Marker
            lat={gems.props.latitude}
            lng={gems.props.longitude}
            color="blue"
          />
        </GoogleMapReact>
        <br>
        </br>
        <h3 style={{color: 'black'}}>{data.length + " Nearby Homes Pictures and Locations"}</h3>
      <Container>
    <Row> 
        <Col>
        <p  style={{color:"black"}}>Property Price: {gems.props.price} </p> </Col>
       
      </Row>
      <Row> 
        <Col>
        <p style={{color:"black"}}>Nearby Home Average: {gems.props.nearbyHomeAvg} </p></Col>
        
      </Row>
      <Row> 
        <Col><p  className={checkPositive(gems.priceIncreasePercentage) ? 'lowpercent' : 'highpercent' }>{buildPercentageString(gems.props.priceIncreasePercentage)}</p></Col>
          
      </Row>
     
 
    </Container>
       
        
        
        {data.map((gem, index) => (  
          <Row>
          <Col  >  
          <Card    class="h-600">
       
          <CardHeader  >
            </CardHeader>
            <CardBody>
            <img width="100%" src={gem.image} alt="Image Unavailable" />
        </CardBody>
        <CardBody>
           <h2>Nearby Home Price: {currencyFormat(gem.price)}</h2>
        </CardBody>
            </Card>
            </Col>
            </Row>
        ))} ;
 
    
      </div>
    );
  
}

export default Nearby;