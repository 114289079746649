//Modal.js
import React, { useEffect, useState ,useRef } from "react";
import ReactDom from "react-dom"; 
//import 'bootstrap/dist/css/bootstrap.css';
import { Col, Row } from "reactstrap"; 
//import SalesChart from "../components/dashboard/SalesChart";
//import ProjectTables from "../components/dashboard/ProjectTable";
import Share from "./Share.component"
import {Modal, Button} from 'react-bootstrap';
import './ModalStyles.css';
//  import {
//   MDBCarousel,
//   MDBCarouselInner,
//   MDBCarouselItem,
//   MDBView,
//   MDBContainer
// } from 'mdbreact'

export const SharePropertyModal = ({ setShowSharePropertyModal, gems }) => {
  // close the modal when clicking outside the modal.
 // const [zpid] = React.useState(String(route.route.params.zpid));
 const chartDataArray = [];
 const labelsArray = [];
  const [isLoading, setLoading] = useState(true);
  const [priceData, setpriceData] = useState([]);
  const [loadOnce,setLoadOnce] = useState(0); 
  const modalRef = useRef(); 
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
        setShowSharePropertyModal(false);
    }
  };
   
 
const handleClose = () => setShowSharePropertyModal(false);
const handleShow = () => setShowSharePropertyModal(true);
  useEffect(() => {
    //const encodedValue = encodeURIComponent(zpid);
    //
    //fetch('https://propertygems.azurewebsites.net/PropertyInfo/getPriceInfo?zpid=' + gems.zpid)
    // fetch('https://localhost:49153/PropertyInfo/getPriceInfo?zpid=' + gems.zpid)
    // .then((response) => response.json())
    // .then((json) => setpriceData(json),setLoadOnce(loadOnce +  1))
    // .catch((error) => console.error(error))
    // .finally(() => setLoading(false));
    // console.log(priceData);
    
    
}, []);
// if (priceData.length != labelsArray.length){
//     priceData.map((priceinfo, index) => {
//         console.log(priceinfo.price)
//         //if(priceinfo.priceChangeRate>0)
//         //{
//             labelsArray.push(priceinfo.epochConvertedTime.slice(0, -8));
//             chartDataArray.push(priceinfo.price);

//         //}
//     });
// }
//  <Share  data={gems} />
  //render the modal JSX in the portal div.
  return (
    <>
      <Button className="nextButton" onClick={{handleShow}}>
        Open Modal
      </Button>

      <Modal   show={setShowSharePropertyModal} onHide={handleClose} aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header >
          <Modal.Title  id="example-custom-modal-styling-title">Share This Property! </Modal.Title>
         
        </Modal.Header>
        <Modal.Body> 
        <Share  data={gems} />
        </Modal.Body>
        <Modal.Footer>
          <Button  className="mybutton"  style={{height: '45px', width : '80px'}}  onClick={handleClose}>
            Close
          </Button>
        
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SharePropertyModal; 