import React,{ useEffect,useState }  from 'react';
import Text from 'react';
import GoogleMapReact from 'google-map-react';
import Marker  from './Markers.tsx';
import { Dimmer, Loader } from "semantic-ui-react";
import {API_URL} from '../Constants'
import Container from '@mui/material/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Icon, Label } from 'semantic-ui-react'
import tw from "twin.macro";
// import './ModalStyles.css';
// import './modal.css';
import {
  CardHeader,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Button
} from "reactstrap";
const ModalHeader = tw.p`text-3xl text-primary-500 font-bold mb-5`;
const ModalSubHeader = tw.p`text-xl text-gray-700 font-bold mb-0`;
const AnyReactComponent = ({ text }) => <div>{text}</div>;
function currencyFormat(num) {
  if(num)
  {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }else 
  {
    return ' Not Available'
  }
  
}
const Nearby = ({gems}) => {
 console.log("gems" + gems); 
   const defaultProps = {
    height:'70',
    center: {
      lat: gems.latitude,
      lng: gems.longitude
    },
    zoom: 18
  };
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  function setDefaultProps(gems) {
    const mapCenterLat = gems.latitude;
    const mapCenterLng = gems.longitude ;
   
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function checkPositive(num) {
    
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  useEffect(() => {
  
  
    const encodedValue = encodeURIComponent(gems.zpid);
    fetch( API_URL + '/PropertyInfo/getNearbyInfo?parentzpid=' + gems.zpid + '&loadid=' + gems.loadID)
        .then((response) => response.json())
        .then((json) => {setData(json); setActive(false)})
        .catch((error) => console.error(error))
        .finally(() => setDefaultProps(gems));
    }, []);
    return (  
      // Important! Always set the container height explicitly
 
      <div style={{height: '600px', width: '100%'}}>
          <div className='Loader'>
           
           <Loader active = {active} className='workaround' size='large' inline='centered' />
       
       </div>
<ModalHeader>Get To Know The Neighborhood. {data.length + " Nearby Homes Pictures and Locations"}  </ModalHeader>
 
 
<div style={{height: '300px', width: '100%'}}>
        <GoogleMapReact
          height={4}
          bootstrapURLKeys={{ key: 'AIzaSyCETi424NnsIH2Chp6XbmhGvHSRni6ok-w' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
         // onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
        {data.map((gem2, index2) => (         
          <Marker
            lat={gem2.coordinate.latitude}
            lng={gem2.coordinate.longitude}
            color="red"
          />
          ))} 
          <Marker
            lat={gems.latitude}
            lng={gems.longitude}
            color="blue"
          />
        </GoogleMapReact>
        </div>
        <br>
        </br>
     <Container>
    <Row> 
        <Col>
         
        <ModalSubHeader>Property Price: {gems.price}</ModalSubHeader>
        </Col>
 
        <Col>
        <ModalSubHeader>Nearby Home Average: {gems.nearbyHomeAvg}</ModalSubHeader>
 
        </Col>
        
    
        <Col> 
        <p    className={checkPositive(gems.priceIncreasePercentage) ? 'lowpercent' : 'highpercent' }>{buildPercentageString(gems.priceIncreasePercentage)}</p>
        </Col>
          
      </Row>
     
 
    </Container>
       
        
        
        {data.map((gem, index) => (  
            <Container>
          <Row>
          <Col  >  
          <Card  >
       
          <CardHeader  >
            </CardHeader>
            <CardBody>
            <img width="400"  height="400" src={gem.image} alt="Image Unavailable" />
 
        <ModalSubHeader>    Nearby Home Price: {currencyFormat(gem.price)}</ModalSubHeader>
     
        </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
        ))} ;
 
    
      </div>
    );
  
}

export default Nearby;