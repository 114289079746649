//Modal.js
import React, { useEffect, useState ,useRef } from "react";
import ReactDom from "react-dom"; 
//import 'bootstrap/dist/css/bootstrap.css';
import { Col, Row } from "reactstrap"; 
import TaxChart from "./TaxChart";
//import ProjectTables from "../components/dashboard/ProjectTable";
import {Modal, Button} from 'react-bootstrap';
import './ModalStyles.css';
import {API_URL} from '../Constants'
//  import {
//   MDBCarousel,
//   MDBCarouselInner,
//   MDBCarouselItem,
//   MDBView,
//   MDBContainer
// } from 'mdbreact'

export const TaxHistoryModal = ({ setShowTaxHistoryModal, gems }) => {
  // close the modal when clicking outside the modal.
 // const [zpid] = React.useState(String(route.route.params.zpid));
 const chartDataArray = [];
 const labelsArray = [];
  const [isLoading, setLoading] = useState(true);
  const [loadOnce,setLoadOnce] = useState(0); 
  const [taxData, settaxData] = useState([]);
  const count =0 ; 
  const modalRef = useRef();  
  const closeModal = (e) => {
        setShowTaxHistoryModal(false);
  };
 
const handleClose = () => setShowTaxHistoryModal(false);
const handleShow = () => setShowTaxHistoryModal(true);

  useEffect(() => {
    //const encodedValue = encodeURIComponent(zpid);
    fetch(API_URL +  '/PropertyInfo/getTaxHistory?zpid=' + gems.zpid  + '&loadid=' + gems.loadID)
    .then((response) => response.json())
    .then((json) => settaxData(json))
    .catch((error) => console.error(error))
    .finally(() => setLoading(false));
    
    setLoadOnce(loadOnce +  1)
    
    
}, []);
if (taxData.length != labelsArray.length){
    taxData.map((taxinfo, index) => {
        console.log(taxinfo.price)
        //if(priceinfo.priceChangeRate>0)
        //{
            labelsArray.push(taxinfo.convertedTime.slice(0, -10));
            chartDataArray.push(taxinfo.taxPaid);
    
        //}
    });
}
 //<TaxChart data={chartDataArray} labels={labelsArray} />
  //render the modal JSX in the portal div.
  return (
    <>
      <Button className="nextButton" onClick={{handleShow}}>
        Open Modal
      </Button>

      <Modal  dialogClassName="modal-100w"  show={setShowTaxHistoryModal} onHide={handleClose} aria-labelledby="example-custom-modal-styling-title">
        <Modal.Header >
          <Modal.Title  id="example-custom-modal-styling-title"> Available Tax History</Modal.Title>
         
        </Modal.Header>
        <Modal.Body> 
        <TaxChart data={chartDataArray} labels={labelsArray} />
        </Modal.Body>
        <Modal.Footer>
          <Button  className="mybutton"  style={{height: '45px', width : '80px'}}  onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default TaxHistoryModal; 