import React, { useState , useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { Dimmer, Loader } from "semantic-ui-react";
//import ReactGA from 'react-ga';
import {API_URL} from '../../Constants'; 
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg"; 
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { Link } from 'react-router-dom';
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;
const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;




export default () => {
  const [sliderRef, setSliderRef] = useState(null);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
  const [orderBy, setOrderBy] = useState("ourRating");
  const [city,setCity]= useState("Houston");
  const [min,setMin]= useState(50000);
  const [max,setMax]= useState(950000);
  const [offSet,setOffSet]= useState(0); 
  const [isLoading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [totalPages, setTotalPages] = useState();
  const [data, setData] = useState([]);
  const [gemCount, setGemCount] = useState();
  const [email, setEmail]= useState();  
  const [cardRowCount, setCardRowCount]= useState(0);  
  const [active, setActive] = useState(true);
  const [weeksCount,setWeeksCount] = useState([]);
  const [checked, setChecked] = React.useState(false);



   useEffect(() => {
   // ReactGA.pageview(window.location.pathname);
   // ReactGA.pageview(window.location.pathname + window.location.search);
    //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';
 
    
    const urltosend = API_URL + '/Display/GetFeaturedMultiProps'; 

    fetch(urltosend)
      .then((response) => response.json())
      .then((json) => {setData(json); setActive(false); setGemCount(json.gemCount[0].gemCount)})
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
 
    

      //setTotalPages(calculateTotalPages(data));
  }, []);
 
  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function checkPositive(num) {
      
    if (num<0){
      return false
    }else if (num>0) 
    {
      return true
    } 
  }
  function buildPercentageString(num) {
  
    if (num<0){
      return num + '% below nearby home average. '
    }else if (num>0) 
    {
      return num + '% above nearby home average. '
    } 
  }
  function buildSimilarPercentageString(num) {
    
    if (num<0){
      return num + '% below similar property average. '
    }else if (num>0) 
    {
      return num + '% above similar property average. '
    } 
  }

  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Featured Multi-Family</HeadingTitle>
              <HeadingDescription>
                Investing in multi-family real estate is a great way to generate additional income without lifting a finger.
              </HeadingDescription>
              <PrimaryLink>
                View All  <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {data.map((gem, index) => (
           <Card key={index}>
           <CardImage imageSrc={gem.imgSrc} />
           <TextInfo>
             <TitleReviewContainer>
               <Title>{gem.address}</Title>
               <RatingsInfo>
                 <StarIcon />
                 <Rating>{gem.ourRating}</Rating>
               </RatingsInfo>
             </TitleReviewContainer>
             <SecondaryInfoContainer>
               <IconWithText>
                 <IconContainer>
                   <LocationIcon />
                 </IconContainer>
                 <Text>{gem.city}</Text>
               </IconWithText>
               <IconWithText>
                 <IconContainer>
                   <PriceIcon />
                 </IconContainer>
                 <Text>{currencyFormat(gem.price)}</Text>
               </IconWithText>
             </SecondaryInfoContainer>
             <Description> 
               <div style={{fontSize: "15px",color:checkPositive(gem.priceIncreasePercentage) ? 'red' : 'green'}}   fontSize="large" >{buildPercentageString(gem.priceIncreasePercentage)}</div>
             <div style={{fontSize: "15px",color:'black' }} >{currencyFormat(gem.nearbyHomeAvg) + " Nearby Home Avg"}</div>
             </Description>
             <Description>
             <div style={{fontSize: "15px",color:checkPositive(gem.similarpriceincreasepercentage) ? 'red' : 'green'}}   fontSize="large" >{buildSimilarPercentageString(gem.similarpriceincreasepercentage)}</div>
                       <div style={{fontSize: "15px",color:'black' }} >{currencyFormat(gem.similarHomeAvg) + " Similar Home Avg"}</div>
             </Description>
             
           </TextInfo>
           <PrimaryButton   ><Link to={"/Details" + '?zpid=' + gem.zpid.trim() + '&loadid=' + gem.loadId}><PrimaryButton   >View More</PrimaryButton></Link></PrimaryButton>
         </Card>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
