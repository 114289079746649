import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Typography from '@mui/material/Typography';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";

import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Header from "components/headers/light.js";
import { API_URL } from '../Constants'
import { Pagination } from 'semantic-ui-react'
import { Dimmer, Loader } from "semantic-ui-react";
import { Dropdown } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import Footer from "components/footers/MiniCenteredFooter.js";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import "../legacy/Card2.css";
import ReactGA from 'react-ga';
import PropCards from './PropCards';
import AdBanner from "components/forms/AdBanner";
import '../legacy/ModalStyles.css';
import SimpleSubscribeNewsletter from 'components/forms/SimpleSubscribeNewsletter';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import StyledPropCard from './StyledPropCard/StyledPropCard'
import StyledPropCard2 from './StyledPropCard/StyledPropCard2'

import { useSearchParams } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
  padding: 4,
  textAlign: 'Left',
}));
const ItemBottomGrid = styled(Paper)(({ theme }) => ({
  backgroundColor: 1 === 2 ? '#1A2027' : '#fff',
  padding: 4,
  textAlign: 'Left',
  color: '#fff',
}));

const FilterDiv = tw.div`pb-12`;
const FilterPaging = tw.div`pb-2`;
//const Container = tw.div`rel  ative`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/4`;
const CardColumn = tw(Column)`w-full pb-16  md:w-1/2 xl:w-1/4 mt-16 xl:mt-0`;
const CardStuff = tw.div``;
const HeadingInfoContainer = tw.div` text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`border-solid flex flex-1 flex-col justify-between h-full border-2 mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`  font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold  text-lg`;
const CardPriceAmountgreen = tw.span`font-bold  text-lg text-green-600`;
const CardTitle = tw.h5`text-xl mt-4 font-bold  `;

const CardMeta = styled.div`
  ${tw`h-64   flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8 align-bottom`;

const FilterContainer = styled.div`
  ${tw`flex flex-wrap justify-between`}
  @media (max-width: 768px) {
    ${tw`flex-col`}
  }
`;

const FilterItem = styled.div`
  ${tw`mb-4 md:mb-0`}
  @media (max-width: 768px) {
    ${tw`mb-4`}
  }
`;

function currencyFormat(num) {
  if (num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return 'Not Available'
  }
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};


export default () => {

  const MinValueListData = [
    { key: 1, text: '$50,000', value: 50000 },
    { key: 2, text: '$100,000', value: 100000 },
    { key: 3, text: '$150,000', value: 150000 },
    { key: 4, text: '$200,000', value: 200000 },
    { key: 5, text: '$250,000', value: 250000 },
    { key: 6, text: '$300,000', value: 300000 },
    { key: 7, text: '$350,000', value: 350000 },
    { key: 8, text: '$400,000', value: 400000 },
    { key: 9, text: '$450,000', value: 450000 },
    { key: 10, text: '$500,000', value: 500000 },
    { key: 11, text: '$550,000', value: 550000 },
    { key: 12, text: '$600,000', value: 600000 },
    { key: 13, text: '$650,000', value: 650000 },
    { key: 14, text: '$700,000', value: 700000 },
    { key: 15, text: '$750,000', value: 750000 },
    { key: 16, text: '$800,000', value: 800000 },
    { key: 17, text: '$850,000', value: 850000 },
    { key: 18, text: '$900,000', value: 900000 },
    { key: 19, text: '$950,000', value: 950000 },
    { key: 20, text: '$1,000,000', value: 1000000 },

  ]
  const MaxValueListData = [
    { key: 1, text: '$50,000', value: 50000 },
    { key: 2, text: '$100,000', value: 100000 },
    { key: 3, text: '$150,000', value: 150000 },
    { key: 4, text: '$200,000', value: 200000 },
    { key: 5, text: '$250,000', value: 250000 },
    { key: 6, text: '$300,000', value: 300000 },
    { key: 7, text: '$350,000', value: 350000 },
    { key: 8, text: '$400,000', value: 400000 },
    { key: 9, text: '$450,000', value: 450000 },
    { key: 10, text: '$500,000', value: 500000 },
    { key: 11, text: '$550,000', value: 550000 },
    { key: 12, text: '$600,000', value: 600000 },
    { key: 13, text: '$650,000', value: 650000 },
    { key: 14, text: '$700,000', value: 700000 },
    { key: 15, text: '$750,000', value: 750000 },
    { key: 16, text: '$800,000', value: 800000 },
    { key: 17, text: '$850,000', value: 850000 },
    { key: 18, text: '$900,000', value: 900000 },
    { key: 19, text: '$950,000', value: 950000 },
    { key: 20, text: '> 1,000,000', value: 100000000 },

  ]
  const propertyTypesListData = [
    { key: 'Houses', id: 0, text: 'Single Family', value: 'SINGLE_FAMILY' },
    { key: 'MultiFamily', id: 1, text: 'Multi-Family', value: 'MULTI_FAMILY' },
    { key: 'MultiFamily', id: 2, text: 'Townhomes', value: 'TOWNHOUSE' },
    { key: 'MultiFamily', id: 3, text: 'Condos', value: 'CONDO' },

  ]
  const orderByListData = [
    { key: 1, text: 'Investment Rating Descending', value: 'ourRating' },
    { key: 2, text: 'Investment Rating Ascending', value: 'ourRatingAsc' },
    { key: 3, text: 'Price Descending', value: 'Price' },
    { key: 4, text: 'Price Ascending', value: 'priceaccending' },
  ]
  const CityData = [
    { key: 1, text: 'Houston', value: "Houston" },
    { key: 2, text: 'Austin', value: "Austin" },
    { key: 3, text: 'Miami', value: "Miami" },
    { key: 4, text: 'San Francisco', value: "San Francisco" }
  ]
  function calculateOffset() {
    return (itemsPerPage * currentPageNum) + 1
  }
  const handleChangePropertyType = (e, dropdowninfo) => {
    setPropertyType(dropdowninfo.value);
  }
  const handleChangeCityData = (e, dropdowninfo) => {
    setCity(dropdowninfo.value);
    setCityMapURL("/CityMap?city=" + dropdowninfo.value);
  }
  const handleChangeMin = (e, dropdowninfo) => {
    setMin(dropdowninfo.value);
  }
  const handleChangeMax = (e, dropdowninfo) => {
    setMax(dropdowninfo.value);
  }
  const handleChangeOrderBy = (e, dropdowninfo) => {
    setOrderBy(dropdowninfo.value);
  }
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [displayOrder, setDisplayOrder] = useState("high");
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [propertyType, setPropertyType] = useState("SINGLE_FAMILY");
  const [orderBy, setOrderBy] = useState("ourRating");
  const [city, setCity] = useState("Houston");
  const [min, setMin] = useState(50000);
  const [max, setMax] = useState(950000);
  const [offSet, setOffSet] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [totalPages, setTotalPages] = useState();
  const [data, setData] = useState([]);
  const [gemCount, setGemCount] = useState();
  const [email, setEmail] = useState();
  const [cardRowCount, setCardRowCount] = useState(0);
  const [active, setActive] = useState(true);
  const [weeksCount, setWeeksCount] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [over90, setOver90] = useState(false);
  const [showModalVal, setShowModalVal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [eid, setEid] = useState(searchParams.get('eid'));

  const [area, setArea] = useState(searchParams.get('area'));

  // const [currentEmail, setCurrentEmail] = useState( getCookie("email"));
  const [currentEmail, setCurrentEmail] = useState("pelbert@gmail.com");

  const [showModal, setShowModal] = useState(true);
  const openModal = () => {
    setShowModal(true);
  };
  const [showPriceHistoryModal, setShowPriceHistoryModal] = useState(false);
  const openPriceHistoryModal = () => {
    setShowPriceHistoryModal(true);
  };
  const [showTaxHistoryModal, setShowTaxHistoryModal] = useState(false);
  const openTaxHistoryModal = () => {
    setShowTaxHistoryModal(true);
  };
  const [showNearbyModal, setShowNearbyModal] = useState(false);
  const openNearbyModal = () => {
    setShowNearbyModal(true);
  };
  const [showSharePropertyModal, setShowSharePropertyModal] = useState(false);
  const openSharePropertyModal = () => {
    setShowSharePropertyModal(true);
  };
  const [CityMapURL, setCityMapURL] = useState("/CityMap?city=" + city)


  const parms = { offsetval: offSet, itemsperpageval: itemsPerPage, propertytypeval: propertyType, minpriceval: min, maxpriceval: max, orderby: orderBy, city: city };

  function refreshData() {
    setActive(true);

    //const urltosendRefresh = 'https://localhost:49153/Display/GetInitialLoad?offset=' + offSet + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType.value + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '';
    const urltosendRefresh = API_URL + '/Display/GetInitialLoad?offset=0&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city + '&email=' + currentEmail + '&over90=' + over90;

    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';

    fetch(urltosendRefresh)
      .then((response) => response.json())
      .then((json) => { setData(json.model); setActive(false); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount)) })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

    // setTotalPages(calculateTotalPages(data));
  }
  function calculateTotalPages(totalcount) {
    return Math.ceil(totalcount / itemsPerPage);
  }
  function toggleOver90(val) {
    if (val === true) {
      setOver90(false);
      console.debug("New Over 90 Val: " + over90);
    } else {
      setOver90(true);
      console.debug("New Over 90 Val: " + over90);
    }

  }
  function createMapUrl(lat, lng, zpid, propertytype, city) {
    var url = "/CityMapZpid?lat=" + lat + "&longitude=" + lng + "&propertytype=" + propertytype + "&zpid=" + zpid.trim() + "&city=" + city
    return url;



  }
  function currencyFormat(num) {
    if (num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return 'Not Available'
    }

  }
  function checkZero(num) {
    if (num > 0) {
      const val = "Gross Rental Yield " + num + "%";
      return val;
    } else {
      return "Gross Rental Yield Unavailable ";
    }
  }
  function checkPositive(num) {

    if (num < 0) {
      return false
    } else if (num > 0) {
      return true
    }
  }
  function checkIsBookMarked(val) {
    if (val == 'Y') {
      return true
    } else {
      return false
    }
  }
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  function refreshDataPaging(offsetvalue) {
    setActive(true);

    //const urltosendRefresh = 'https://localhost:49153/Display/GetInitialLoad?offset=' + offSet + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType.value + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city +  '';
    const urltosendRefresh = API_URL + '/Display/GetInitialLoad?offset=' + offsetvalue + '&itemsperpage=' + itemsPerPage + '&propertytype=' + propertyType + '&minprice=' + min + '&maxprice=' + max + '&orderby=' + orderBy + '&city=' + city + '&email=' + currentEmail + '&over90=' + over90;

    //const urltosend = 'https://propertygems.azurewebsites.net/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '';

    fetch(urltosendRefresh)
      .then((response) => response.json())
      .then((json) => { setData(json.model); setActive(false); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount)) })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

    // setTotalPages(calculateTotalPages(data));
  }
  const onChange = (e, pageInfo) => {
    setCurrentPageNum(pageInfo.activePage);
    const offsetVal = (pageInfo.activePage - 1) * (itemsPerPage + 1)
    setOffSet((pageInfo.activePage - 1) * (itemsPerPage + 1));
    refreshDataPaging(offsetVal);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    ReactGA.pageview("/Investments");
    //  ReactGA.pageview(window.location.pathname);
    // ReactGA.pageview(window.location.pathname + window.location.search);
    //const urltosend = 'https://localhost:49153/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) +  '';

    //let currentEmail = getCookie("email");
    let currentEmail = "pelbert@gmail.com";
    var urltosend = "";
    if (eid == null) {
      urltosend = API_URL + '/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) + '&email=' + encodeURIComponent(currentEmail) + "&displayOrder=" + encodeURIComponent(displayOrder);
      console.log("null");
    } else {
      urltosend = API_URL + '/Display/GetInitialLoad?offset=' + encodeURIComponent(parms.offsetval) + '&itemsperpage=' + encodeURIComponent(parms.itemsperpageval) + '&propertytype=' + encodeURIComponent(parms.propertytypeval) + '&minprice=' + encodeURIComponent(parms.minpriceval) + '&maxprice=' + encodeURIComponent(parms.maxpriceval) + '&orderby=' + encodeURIComponent(parms.orderby) + '&city=' + encodeURIComponent(parms.city) + '&email=' + encodeURIComponent(currentEmail) + "&displayOrder=" + encodeURIComponent(displayOrder) + '&eid=' + eid + '&zpid=' + area;
      console.log("not null");

    }
    const countURL = API_URL + '/PropertyInfo/getWeeksPropertyCount';

    fetch(urltosend)
      .then((response) => response.json())
      .then((json) => { setData(json.model); setActive(false); setGemCount(json.gemCount[0].gemCount); setTotalPages(calculateTotalPages(json.gemCount[0].gemCount)) })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));

    fetch(countURL)
      .then((response) => response.json())
      .then((json) => { setWeeksCount(json) })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));


    //setTotalPages(calculateTotalPages(data));
  }, []);

  const yieldTooltip = "Gross rental yield refers to the gross yield produced by a rental property. It's simply a function of the rent collected over the course of a year expressed as a percentage of the property's market value. A rental property with a market value of $100,000 that generates $12,000 in rental income each year would have a gross rental yield of 12%. Gross rental yield is not reflective of the actual profits (if any) an investment property generates. It is only based on annual rental income. It doesnt take expenses such as property taxes, management fees, and other operating expenses into account. This would be the net rental yield. In fact, it is certainly possible for a property to have a strong gross rental yield and still produce poor cash flow for its owner.For this reason, gross rental yield can be useful for a quick comparison between two investment properties or to narrow down a list of potential investments. ";
  const ShowAd = (index) => {
    console.log("in ad");
    if ((index.index % 3) === 0) {
      return <AdBanner></AdBanner>;
    }

  }


  return (
    <Container maxWidth="xl">

      <Header />
      <br></br>
      <Hero />

      <br></br>
      <div><SimpleSubscribeNewsletter /></div>
      <div className='Loader'>
        <Dimmer
          active={active}
          page={true} size='massive' inverted>
          <Loader inverted>Finding Top Investments!</Loader>
        </Dimmer>
      </div>
      <Content>

        <div>
          <Box
            component="span"
            sx={{
              p: 2,
              border: 3,
              borderColor: "#2e93d1",
              display: "inline-block",
              textAlign: "center"
            }}          >
            <p style={{ fontSize: "25px" }}>FILTER PROPERTIES</p>
            <br></br>
            <FilterContainer>
              <FilterItem>
                <p style={{ fontSize: "17px" }}>Property Type</p>
                <Dropdown selection={propertyType} onChange={handleChangePropertyType} placeholder={propertyType} options={propertyTypesListData} />
              </FilterItem>
              <FilterItem>
                <p style={{ fontSize: "17px" }}>City</p>
                <Dropdown onChange={handleChangeCityData} placeholder={city} options={CityData} selection={city} />
              </FilterItem>
              <FilterItem>
                <p style={{ fontSize: "17px" }}>Minimum Value</p>
                <Dropdown onChange={handleChangeMin} placeholder={min} options={MinValueListData} selection={min} />
              </FilterItem>
              <FilterItem>
                <p style={{ fontSize: "17px" }}>Maximum Value</p>
                <Dropdown onChange={handleChangeMax} placeholder={max} options={MaxValueListData} selection={max} />
              </FilterItem>
              <FilterItem>
                <p style={{ fontSize: "17px" }}>Order By</p>
                <Dropdown selection={orderBy} placeholder={orderBy} onChange={handleChangeOrderBy} options={orderByListData} />
              </FilterItem>
              <FilterItem>
                <FormGroup>
                  <FormControlLabel style={{ fontSize: "25px" }} control={<Checkbox id='over90' sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} checked={over90} onChange={(e) => toggleOver90(over90)} />} label="Show only Properties on the market for more than 90 days. Seller might be willing to entertain a lower offer." />
                </FormGroup>
              </FilterItem>
              <FilterItem style={{ textAlign: 'Left' }}>
                <Button primary onClick={refreshData}>Refresh Investments</Button>
              </FilterItem>
            </FilterContainer>
          </Box>
          <br></br> <br></br>
          <a href={CityMapURL} target='_blank'>
            <Button primary onClick={refreshData}>Show Full City Investment Map For {city}</Button>
          </a>
          <br></br>      <br></br>
          <FilterPaging>
            <Pagination activePage={currentPageNum} onPageChange={onChange} totalPages={totalPages} />
          </FilterPaging>
        </div>
        <br></br>
        <Grid container spacing={4}>

          {data.map((card, index) => (
            <>
              <Grid item lg={3} xs={12} >
                {/* <ShowAd index={parseInt(index)}></ShowAd> */}
                <StyledPropCard2
                  id={index}
                  image={card.imgSrc}
                  price={currencyFormat(card.price)}
                  shortdesc={card.description.length > 100 ? card.description.substring(0, 98) + "..." : card.description}
                  description={card.description}
                  address={card.address}
                  priceIncreasePercentage={card.priceIncreasePercentage}
                  grossRentalYield={card.grossRentalYield}
                  mapUrl={createMapUrl(card.latitude, card.longitude, card.zpid, card.propertytype, card.city)}
                  color="primary"
                  zpid={card.zpid}
                  nearbyHomeAvg={currencyFormat(card.nearbyHomeAvg)}
                  imageList={card.imageList}
                  ourRating={card.ourRating}
                  communityRating={card.communityRating}
                  rentZestimate={currencyFormat(card.rentZestimate)}
                  similarHomeAvg={currencyFormat(card.similarHomeAvg)}
                  loadID={card.loadId}
                  similarpriceincreasepercentage={card.similarpriceincreasepercentage}
                  isBookMarked={card.isBookMarked}
                  latitude={card.latitude}
                  longitude={card.longitude}
                  mls={card.mls}
                  isNew={card.isNew}
                  totalSqrFt={card.totalSqrFt}
                  pricePerSqrFt={card.pricePerSqrFt}
                  schoolRatingAvg={card.schoolRatingAvg}
                  home_status={card.home_status}
                  priceAction={card.priceAction}
                  investmentDecisionReason={card.investmentDecisionReason}
                  timeOnZillow={card.timeOnZillow}
                  bedrooms={card.bedrooms}
                  over90={over90}
                />
              </Grid>
            </>

          ))}
        </Grid>

        <Pagination activePage={currentPageNum} onPageChange={onChange} totalPages={totalPages} />

      </Content>
      <Footer />

    </Container>
  );
};
